import { lazy } from 'react';
import Loadable from 'ui-component/Loadable';

const HomePage = Loadable(lazy(() => import('Routes1/HomeRoute/homepage')));
const EmployeeRoute = lazy(() => import('../Modules/Employees/EmployeeParentRoute'));

const ReportRoute = Loadable(lazy(() => import('../Modules/Reports/ReportsRoute')));
const LeaveRoute = Loadable(lazy(() => import('../Modules/Leaves/LeavesParentRoute')));
const ClientsRoute = Loadable(lazy(() => import('../Modules/Clients/ClientsParentRoute')));
const VendorsRoute = Loadable(lazy(() => import('../Modules/Vendors/VendorsParentRoute')));
const InvoicesRoute = Loadable(lazy(() => import('../Modules/Invoices/InvoicesParentRoute')));
const PaymentsRoute = Loadable(lazy(() => import('../Modules/Payments/PaymentsParentRoute')));
const MastersRoute = Loadable(lazy(() => import('../Modules/Masters/MastersParent')));
const AccountParen = Loadable(lazy(() => import('../Modules/Accounts/AccountParent')));
const ExpenseRoute = Loadable(lazy(() => import('../Modules/Expenses/ExpensesRoute')));
const PayrollsRoute = Loadable(lazy(() => import('../Modules/Payrolls/PayrollsParentRoute')));

// Pages

// ==============================|| MAIN ROUTING ||============================== //

const MapRoutes = [
    {
        path: '/',
        element: HomePage,
        screenKeyName: 'HomePage'
    },
    {
        path: 'employee-management/*',
        element: EmployeeRoute,
        screenKeyName: 'EmployeeRoute'
    },
    {
        path: 'manage-accounts/reports/*',
        element: ReportRoute,
        screenKeyName: 'ReportRoute'
    },
    {
        path: 'employee-management/leave-management/*',
        element: LeaveRoute,
        screenKeyName: 'LeaveRoute'
    },
    {
        path: 'manage-accounts/client-management/*',
        element: ClientsRoute,
        screenKeyName: 'ClientsRoute'
    },
    {
        path: 'manage-accounts/vendors-management/*',
        element: VendorsRoute,
        screenKeyName: 'VendorsRoute'
    },
    {
        path: 'manage-accounts/invoice-management/*',
        element: InvoicesRoute,
        screenKeyName: 'InvoicesRoute'
    },
    {
        path: 'manage-accounts/payment-management/*',
        element: PaymentsRoute,
        screenKeyName: 'PaymentsRoute'
    },

    {
        path: 'manage-masters/*',
        element: MastersRoute,
        screenKeyName: 'MastersRoute'
    },
    {
        path: 'manage-accounts/*',
        element: AccountParen,
        screenKeyName: 'AccountParen'
    },

    {
        path: 'manage-accounts/expense-management/*',
        element: ExpenseRoute,
        screenKeyName: 'ExpenseRoute'
    },
    {
        path: 'employee-management/payroll-management/*',
        element: PayrollsRoute,
        screenKeyName: 'PayrollsRoute'
    },
];

export default MapRoutes;
