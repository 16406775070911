import { takeLatest, all, call, put } from 'redux-saga/effects';

///Actions
import {
    startEmployeeLeavesFetch,
    setEmployeeLeaves,
    startEmployeeExperienceFetch,
    setEmployeeExperience,
    startEmployeeEducationFetch,
    setEmployeeEducation,
    startEmployeeDocumentFetch,
    setEmployeeDocument,
    startEmployeeAccountFetch,
    setEmployeeAccount,
    startEmployeeAddressFetch,
    setEmployeeAddress,
    startEmployeesDataFetch,
    startEmployeeDataByPageIndex,
    setEmployeesData,
    setEmployeeData,
    completedFetch,
    startEmployeeDataFecth,
    startAddOrEditEmployee,
    startAddorEditEmployeeAddress,
    setEmployeesDataWithPage,
    startDeleteEmployee,
    startAddorEditOfEmployeeAccount,
    startAddorEditOfEmployeeEducation,
    startAddorEditOfEmployeeExperience,
    startDeleteEmployeeEducation,
    startDeleteEmployeeExperience,
    startAddorEditOfEmployeeDocument,
    startDeleteEmployeeDocument,
    startAddorEditOfEmployeePayroll,
    startEmployeeOnlyBasicFecth,
    setEmployeeOnlyBasicData,
    setEmployeesDataWithFilter,
    startEmployeeDataByFilter
} from './employeesData.slice.selector';
import { startUserLogout } from '../user login data/user.login.data.slice.slector';
///API
import {
    BasicPersonalEmployeesData,
    addEditAccountEmployee,
    singleEmployeeData,
    addNewEmployeeData,
    deleteEmployeeData,
    addEditAddressEmployee,
    addEditEducationalEmployee,
    addEditExperienceEmployee,
    deleteEducationalEmployeeData,
    deleteExperienceEmployeeData,
    addEditDocumentEmployee,
    deleteDocumentData,
    addEditPayrollEmployee,
    employeeListByPageIndex,
    singleEmployeeOnlyBasicData,
    deleteEmployeePayrollsDataByEmployeeId,
    addressByEmployeeId,
    accountByEmployeeId,
    educationByEmployeeId,
    experienceByEmployeeId,
    documentByEmployeeId,
    employeeLeavesdataOnly,
    filterEmployeeData
} from '../../Api/EmployeeAPI/api';
import { setNotificationAction } from '../NotificationStore/notification.slice.selector';
import { setFormNavigate } from 'store1/formnavigation/formnavigation';

//watcher //All Employees Data (1)
function* startEmployeDataFetchWatcher() {
    yield takeLatest(startEmployeesDataFetch.type, setEmployeesToStore);
}

/// (1)
function* setEmployeesToStore({ payload }) {
    try {
        let employeesData;
        employeesData = yield call(BasicPersonalEmployeesData, payload);
        yield put(setEmployeesData(employeesData));

        yield put(completedFetch());
    } catch (error) {
        yield put(
            setNotificationAction({
                message: error.message,
                variant: 'error'
            })
        );
        yield put(completedFetch());
    }
}

////(1.1) With Pagination
function* startEmployeDataByPageIdFetchWatcher() {
    yield takeLatest(startEmployeeDataByPageIndex.type, setEmployeesPageToStore);
}

//(1.1) Next
function* setEmployeesPageToStore() {
    try {
        let employeesData;
        employeesData = yield call(employeeListByPageIndex);
        yield put(setEmployeesDataWithPage(employeesData));
        yield put(completedFetch());
    } catch (error) {
        yield put(
            setNotificationAction({
                message: error.message,
                variant: 'error'
            })
        );
        yield put(completedFetch());
    }
}

//watcher employee filter
function* startEmployeDataFilterWatcher() {
    yield takeLatest(startEmployeeDataByFilter.type, setEmployeesFiltered);
}
//(1.1) Next
function* setEmployeesFiltered({ payload }) {
    try {
        let allemployeesDatas;
        allemployeesDatas = yield call(filterEmployeeData, payload);
        yield put(setEmployeesDataWithFilter(allemployeesDatas));
        yield put(completedFetch());
    } catch (error) {
        yield put(
            setNotificationAction({
                message: error.message,
                variant: 'error'
            })
        );
        yield put(completedFetch());
    }
}

//watcher //Single Employees Data (2)
function* startSingleEmployeeFetchWatcher() {
    yield takeLatest(startEmployeeDataFecth.type, setEmployeeToStore);
}
//(2)
function* setEmployeeToStore({ payload }) {
    try {
        const singleEmployee = yield call(singleEmployeeData, payload);
        yield put(setEmployeeData(singleEmployee));
        yield put(completedFetch());
    } catch (error) {
        yield put(
            setNotificationAction({
                message: error.message,
                variant: 'error'
            })
        );
        yield put(completedFetch());
    }
}
//(3) Watcher
function* startAddEditEmployeetWatcher() {
    yield takeLatest(startAddOrEditEmployee.type, setupEmployeetDataAddEdit);
}
//(3) Next
function* setupEmployeetDataAddEdit({ payload }) {
    try {
        const { employeeData, adding, tableData } = payload;
        if (adding) {
            yield call(addNewEmployeeData, employeeData);
            yield put(
                setNotificationAction({
                    message: 'Successfully Added',
                    variant: 'success'
                })
            );
            yield put(completedFetch());
        } else {
            yield call(addNewEmployeeData, employeeData);
            yield put(
                setNotificationAction({
                    message: 'Successfully Updated',
                    variant: 'success'
                })
            );

            yield put(completedFetch());
        }
        yield put(startEmployeeDataByPageIndex(tableData));
        yield put(setFormNavigate(true));
        yield put(completedFetch());
    } catch (error) {
        if (error.response && error.response.status === 409) {
            // Handle 409 Conflict error
            yield put(setNotificationAction({ message: error.response.data.error_message, variant: 'error' }));
        } else {
            yield put(
                setNotificationAction({
                    message: error.message,
                    variant: 'error'
                })
            );
        }
        yield put(setFormNavigate(false));
        yield put(completedFetch());
    }
}
//(4)Watcher
function* startEmployeeDelete() {
    yield takeLatest(startDeleteEmployee.type, setUpForDelete);
}
//(4)Next
function* setUpForDelete({ payload }) {
    try {
        yield call(deleteEmployeeData, payload);
        yield call(deleteEmployeePayrollsDataByEmployeeId, payload);
        yield put(
            setNotificationAction({
                message: 'Successfully Deleted',
                variant: 'error'
            })
        );
        yield put(completedFetch());
        //
    } catch (error) {
        yield put(
            setNotificationAction({
                message: error.message,
                variant: 'error'
            })
        );
        yield put(completedFetch());
        //
    }
}
//(5)
function* startAddEditAddressEmployee() {
    yield takeLatest(startAddorEditEmployeeAddress.type, setUpEmployeeAddress);
}
//(5) Next
function* setUpEmployeeAddress({ payload }) {
    try {
        yield call(addEditAddressEmployee, payload);
        yield put(
            setNotificationAction({
                message: 'Successfully updated',
                variant: 'success'
            })
        );
        yield put(startEmployeeAddressFetch(payload.employee_id));
        yield put(completedFetch());
        //
    } catch (error) {
        yield put(
            setNotificationAction({
                message: error.message,
                variant: 'error'
            })
        );
        yield put(completedFetch());
    }
}
//(6)Watcher
function* startAddEditAccountEmployee() {
    yield takeLatest(startAddorEditOfEmployeeAccount.type, setUpEmployeeAccount);
}
//(6) Next
function* setUpEmployeeAccount({ payload }) {
    try {
        yield call(addEditAccountEmployee, payload?.activeData);
        if (payload?.inActiveData) {
            yield call(addEditAccountEmployee, payload?.inActiveData);
        }
        yield payload?.activeData?.employee_id && put(startEmployeeAccountFetch(payload?.activeData?.employee_id));
        yield put(
            setNotificationAction({
                message: 'Successfully Updated',
                variant: 'success'
            })
        );
        yield put(completedFetch());
        //
    } catch (error) {
        yield put(
            setNotificationAction({
                message: error.message,
                variant: 'error'
            })
        );
        yield put(completedFetch());
        //
    }
}
//(7)Watcher
function* startAddEditEducationEmployee() {
    yield takeLatest(startAddorEditOfEmployeeEducation.type, setUpEmployeeEducation);
}
//(7) Next
function* setUpEmployeeEducation({ payload }) {
    try {
        yield call(addEditEducationalEmployee, payload);
        yield put(
            setNotificationAction({
                message: 'Successfully Updated',
                variant: 'success'
            })
        );
        yield put(startEmployeeEducationFetch(payload.employee_id));
        yield put(completedFetch());
        //
    } catch (error) {
        yield put(
            setNotificationAction({
                message: error.message,
                variant: 'error'
            })
        );
        yield put(completedFetch());
    }
}
//(8)Watcher
function* startAddEdiExperienceEmployee() {
    yield takeLatest(startAddorEditOfEmployeeExperience.type, setUpEmployeeExperience);
}
//(8) Next
function* setUpEmployeeExperience({ payload }) {
    try {
        yield call(addEditExperienceEmployee, payload);
        yield put(startEmployeeExperienceFetch(payload.employee_id));
        yield put(
            setNotificationAction({
                message: 'Successfully Updated',
                variant: 'success'
            })
        );
        yield put(completedFetch());
        //
    } catch (error) {
        yield put(
            setNotificationAction({
                message: error.message,
                variant: 'error'
            })
        );
        yield put(completedFetch());
        //
    }
}
//(9)Watcher
function* startAddEdiDocumentEmployee() {
    yield takeLatest(startAddorEditOfEmployeeDocument.type, setUpEmployeeDocument);
}
//(9) Next
function* setUpEmployeeDocument({ payload }) {
    try {
        yield call(addEditDocumentEmployee, payload);
        yield put(startEmployeeDocumentFetch(payload.employee_id));
        yield put(
            setNotificationAction({
                message: 'Successfully Updated Documents',
                variant: 'success'
            })
        );
        yield put(completedFetch());
        //
    } catch (error) {
        yield put(
            setNotificationAction({
                message: error.message,
                variant: 'error'
            })
        );
        yield put(completedFetch());
        //
    }
}
//(10)Watcher
function* startEmployeeEductionDelete() {
    yield takeLatest(startDeleteEmployeeEducation.type, setUpForDeleteEdu);
}
//(10)Next
function* setUpForDeleteEdu({ payload }) {
    try {
        yield call(deleteEducationalEmployeeData, payload.data);

        yield put(startEmployeeEducationFetch(payload.employee_id));
        yield put(
            setNotificationAction({
                message: 'Successfully Deleted',
                variant: 'error'
            })
        );
        yield put(completedFetch());
        //
    } catch (error) {
        yield put(
            setNotificationAction({
                message: error.message,
                variant: 'error'
            })
        );
        yield put(completedFetch());
        //
    }
}
//(11)Watcher
function* startEmployeeExpericenceDelete() {
    yield takeLatest(startDeleteEmployeeExperience.type, setUpForDeleteExp);
}
//(11)Next
function* setUpForDeleteExp({ payload }) {
    try {
        yield call(deleteExperienceEmployeeData, payload.data);
        yield put(startEmployeeExperienceFetch(payload.employee_id));
        yield put(
            setNotificationAction({
                message: 'Successfully Deleted',
                variant: 'error'
            })
        );
        yield put(completedFetch());
        //
    } catch (error) {
        yield put(
            setNotificationAction({
                message: error.message,
                variant: 'error'
            })
        );
        yield put(completedFetch());
        //
    }
}
//(12)Watcher
function* startEmployeeDocumentDelete() {
    yield takeLatest(startDeleteEmployeeDocument.type, setUpForDeleteDoc);
}
//(12)Next
function* setUpForDeleteDoc({ payload }) {
    try {
        yield call(deleteDocumentData, payload.data);
        yield put(startEmployeeDocumentFetch(payload.employee_id));
        yield put(
            setNotificationAction({
                message: 'Successfully Deleted',
                variant: 'error'
            })
        );
        yield put(completedFetch());
        //
    } catch (error) {
        yield put(
            setNotificationAction({
                message: error.message,
                variant: 'error'
            })
        );
        yield put(completedFetch());
        //
    }
}
//(13)Watcher
function* startAddEditPayrollEmployee() {
    yield takeLatest(startAddorEditOfEmployeePayroll.type, setUpEmployeePayroll);
}
//(13) Next
function* setUpEmployeePayroll({ payload }) {
    try {
        yield call(addEditPayrollEmployee, payload);
        yield put(
            setNotificationAction({
                message: 'Successfully Added',
                variant: 'success'
            })
        );
        yield put(completedFetch());
    } catch (error) {
        yield put(
            setNotificationAction({
                message: error.message,
                variant: 'error'
            })
        );
        yield put(completedFetch());
    }
}

//(15)Watcher
function* startSingleEmployeeOnlyBasicFetchWatcher() {
    yield takeLatest(startEmployeeOnlyBasicFecth.type, setEmployeeOnlyBasicToStore);
}
//(15) Next
function* setEmployeeOnlyBasicToStore({ payload }) {
    try {
        const singleEmployeeOnlyBasic = yield call(singleEmployeeOnlyBasicData, payload);
        yield put(setEmployeeOnlyBasicData(singleEmployeeOnlyBasic));
        yield put(completedFetch());
    } catch (error) {
        yield put(
            setNotificationAction({
                message: error.message,
                variant: 'error'
            })
        );
        yield put(completedFetch());
    }
}

///////////////Especially for Tabs view
//(1)Watcher
function* startSingleLeavesFetchWatcher() {
    yield takeLatest(startEmployeeLeavesFetch.type, setEmployeesLeavesToTabToStore);
}
//(1) Next
function* setEmployeesLeavesToTabToStore({ payload }) {
    try {
        const singleEmployeeOnlyBasic = yield call(employeeLeavesdataOnly, payload);
        yield put(setEmployeeLeaves(singleEmployeeOnlyBasic));
        yield put(completedFetch());
    } catch (error) {
        yield put(
            setNotificationAction({
                message: error.message,
                variant: 'error'
            })
        );
        yield put(completedFetch());
    }
}

//(2)Watcher
function* startSingleExperienceFetchWatcher() {
    yield takeLatest(startEmployeeExperienceFetch.type, setEmployeesExperienceToTabToStore);
}
//(2) Next
function* setEmployeesExperienceToTabToStore({ payload }) {
    try {
        const data = yield call(experienceByEmployeeId, payload);
        yield put(setEmployeeExperience(data));
        yield put(completedFetch());
    } catch (error) {
        yield put(
            setNotificationAction({
                message: error.message,
                variant: 'error'
            })
        );
        yield put(completedFetch());
    }
}

//(3)Watcher
function* startSingleEducationFetchWatcher() {
    yield takeLatest(startEmployeeEducationFetch.type, setEmployeesEducationToTabToStore);
}
//(3) Next
function* setEmployeesEducationToTabToStore({ payload }) {
    try {
        const singleEmployeeOnlyBasic = yield call(educationByEmployeeId, payload);
        yield put(setEmployeeEducation(singleEmployeeOnlyBasic));
        yield put(completedFetch());
    } catch (error) {
        yield put(
            setNotificationAction({
                message: error.message,
                variant: 'error'
            })
        );
        yield put(completedFetch());
    }
}
///

//(4)Watcher
function* startSingleDocumentFetchWatcher() {
    yield takeLatest(startEmployeeDocumentFetch.type, setEmployeesDocumentToTabToStore);
}
//(4) Next
function* setEmployeesDocumentToTabToStore({ payload }) {
    try {
        const singleEmployeeOnlyBasic = yield call(documentByEmployeeId, payload);
        yield put(setEmployeeDocument(singleEmployeeOnlyBasic));
        yield put(completedFetch());
    } catch (error) {
        yield put(
            setNotificationAction({
                message: error.message,
                variant: 'error'
            })
        );
        yield put(completedFetch());
    }
}

//5)Watcher
function* startSingleAccountFetchWatcher() {
    yield takeLatest(startEmployeeAccountFetch.type, setEmployeesAccountToTabToStore);
}
//(5) Next
function* setEmployeesAccountToTabToStore({ payload }) {
    try {
        const singleEmployeeOnlyBasic = yield call(accountByEmployeeId, payload);
        yield put(setEmployeeAccount(singleEmployeeOnlyBasic));
        yield put(completedFetch());
    } catch (error) {
        yield put(
            setNotificationAction({
                message: error.message,
                variant: 'error'
            })
        );
        yield put(completedFetch());
    }
}

//(6)Watcher
function* startSingleAddressFetchWatcher() {
    yield takeLatest(startEmployeeAddressFetch.type, setEmployeesAddressToTabToStore);
}
//(6) Next
function* setEmployeesAddressToTabToStore({ payload }) {
    try {
        const data = yield call(addressByEmployeeId, payload);

        yield put(setEmployeeAddress(data));
        yield put(completedFetch());
    } catch (error) {
        yield put(
            setNotificationAction({
                message: error.message,
                variant: 'error'
            })
        );
        yield put(completedFetch());
    }
}

//Accumulator
export default function* employeeDataDataSaga() {
    yield all([
        call(startEmployeDataFetchWatcher),
        call(startEmployeDataByPageIdFetchWatcher),
        call(startEmployeDataFilterWatcher),
        call(startSingleEmployeeFetchWatcher),
        call(startAddEditEmployeetWatcher),
        call(startEmployeeDelete),
        call(startAddEditAddressEmployee),
        call(startAddEditAccountEmployee),
        call(startAddEditEducationEmployee),
        call(startAddEdiExperienceEmployee),
        call(startEmployeeEductionDelete),
        call(startAddEdiDocumentEmployee),
        call(startEmployeeExpericenceDelete),
        call(startEmployeeDocumentDelete),
        call(startAddEditPayrollEmployee),
        call(startSingleEmployeeOnlyBasicFetchWatcher),

        call(startSingleAddressFetchWatcher),
        call(startSingleAccountFetchWatcher),
        call(startSingleDocumentFetchWatcher),
        call(startSingleEducationFetchWatcher),
        call(startSingleExperienceFetchWatcher),
        call(startSingleLeavesFetchWatcher)
    ]);
}
