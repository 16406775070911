import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";

const vendorsDataSlice = createSlice({
  name: "vendorsDataSliceName",
  initialState: {
    isLoading: false,
    allVendorsData: [],
    allStates: [],
    vendorListWithPage: [],
    singleVendorData: {},
    loaderMessage: "",
    backDrop: false,
    filtervendorda: {},
    viewVendors:[],
    pageIndex: 1,
    pageSize: 10,
  },
  reducers: {
    setPage(state, action) {
      state.pageIndex = action.payload;
    },
    setRowsPerPage(state, action) {
      state.pageSize = action.payload;
    },
    startAllVendorsData(state, action) {
      state.isLoading = true;
      state.loaderMessage = "Loading please wait...";
    },
    startVendorsDataByPageIndex(state, action) {
      state.isLoading = true;
      state.loaderMessage = "Loading please wait...";
    },
    startFilterVendorsData(state, action) {
      state.isLoading = true;
      state.loaderMessage = "Loading please wait...";
    },
    startViewVendorsData(state, action) {
      state.isLoading = true;
      state.loaderMessage = "Loading please wait...";
    },
    setVendorViewExpenseList(state, action) {
      state.viewVendors = action.payload;
    },
    setAllVendorsData(state, action) {
      state.allVendorsData = action.payload;
    },
    startAllStates(state, action) {
      state.isLoading = false;
    },
    setAllStates(state, action) {
      state.allStates = action.payload;
    },
    setVendorListWithPage(state, action) {
      state.vendorListWithPage = action.payload;
    },
    startSingleVendorData(state, action) {
      state.isLoading = true;
      state.loaderMessage = "Loading please wait...";
    },
    setSingleVendorData(state, action) {
      state.singleVendorData = action.payload;
    },
    startAddOrEditVendor(state, action) {
      state.isLoading = true;
      state.loaderMessage = "Loading please wait...";
    },

    startDeleteVendor(state, action) {
      state.isLoading = true;
    },
    completedFetch(state, action) {
      state.isLoading = false;
    },
    startAddRestoreVendor(state, action) {
      state.isLoading = true;
    },
  },
});

export const {
  setPage,
  setRowsPerPage,
  startAllVendorsData,
  startVendorsDataByPageIndex,
  startAllStates,
  setAllStates,
  startFilterVendorsData,
  startViewVendorsData,
  setVendorViewExpenseList,
  setAllVendorsData,
  setVendorListWithPage,
  completedFetch,
  startSingleVendorData,
  setSingleVendorData,
  startAddOrEditVendor,
  startDeleteVendor,
  startAddRestoreVendor
} = vendorsDataSlice.actions;
export default vendorsDataSlice.reducer;

///////////////////////////////////////////////////////////////
///selector
const mainVendorState = (state) => state.VendorsData;
//
export const isLoadingSelectorVendor = createSelector(
  [mainVendorState],
  (vendorDataState) => vendorDataState.isLoading
);
//
export const allVendorsDataSelector = createSelector(
  [mainVendorState],
  (vendorDataState) => vendorDataState.allVendorsData
);
//
export const allVendorsStateSelector = createSelector(
  [mainVendorState],
  (vendorDataState) => vendorDataState.allStates
);
//
export const FilterVendorDataSelector = createSelector(
  [mainVendorState],
  (vendorDataState) => vendorDataState.filtervendorda
);
//
export const vendorListDataWithPageSelector = createSelector(
  [mainVendorState],
  (vendorDataState) => vendorDataState.vendorListWithPage
);
//
export const vendorDataSelector = createSelector(
  [mainVendorState],
  (vendorDataState) => vendorDataState.singleVendorData
);
//

export const viewExpenseVendorDataSelector = createSelector(
  [mainVendorState],
  (vendorDataState) => vendorDataState.viewVendors
);
//

export const loaderMessageSelector = createSelector(
  [mainVendorState],
  (vendorDataState) => vendorDataState.loaderMessage
);
export const pageIndexSelector = createSelector(
  [mainVendorState],
  (vendorDataState) => vendorDataState.pageIndex
);
export const pageSizeSelector = createSelector(
  [mainVendorState],
  (vendorDataState) => vendorDataState.pageSize
);