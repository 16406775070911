import { takeLatest, all, call, put } from "redux-saga/effects";
import {
  startUserLogin,
  startCompanyData,
  setCompanyData,
  setUserData,
  startUserLogout,
  setNullAfterLogout,
  setTrueOnRefreshPage,
  startTogetRefreshToken,
  completedFetch,
} from "./user.login.data.slice.slector";
import {
  refreshAuthToken,
  loginUserAuthRequest,
  logoutUserApi,
  getMyCompanyData,
} from "../../Api/UserAuthAPI/userAuth";

///(1) Watcher
function* startLoginData() {
  yield takeLatest(startUserLogin.type, setData);
}
//(1) Next
function* setData({ payload }) {
  try {
    const response = yield call(loginUserAuthRequest, payload);
    yield put(setUserData(response));
    yield put(completedFetch());
  } catch (error) {
    yield put(completedFetch()); 
  }
}

///(2) Watcher
function* startRefreshToken() {
  yield takeLatest(startTogetRefreshToken.type, setRefData);
}
//(2) Next
export function* setRefData() {
  try {
    const response = yield call(refreshAuthToken);
    yield put(setTrueOnRefreshPage());
    const data = yield call(getMyCompanyData);
    yield put(setCompanyData(data));
    yield put(setUserData(response));
    yield put(completedFetch());
  } catch (error) {
    yield call(setLogoutData);
    yield put(completedFetch());
  }
}
///(3)
function* startLogoutData() {
  yield takeLatest(startUserLogout.type, setLogoutData);
}
//(3) Next
function* setLogoutData() {
  yield call(logoutUserApi);
  yield put(setNullAfterLogout());
  yield put(completedFetch());
}

///(4)
function* startGetCompanyData() {
  yield takeLatest(startCompanyData.type, setMyCompanyData);
}
//(4) Next
function* setMyCompanyData({ payload }) {
  const data = yield call(getMyCompanyData, payload);
  yield put(setCompanyData(data));
  yield put(completedFetch());
}
//Accumulator
export default function* userDataSaga() {
  yield all([
    call(startLoginData),
    call(startLogoutData),
    call(startRefreshToken),
    call(startGetCompanyData),
  ]);
}
