import storage from "redux-persist/lib/storage";
import persistReducer from "redux-persist/es/persistReducer";

const persistConfig = {
    key: 'my-mis-shdy-app-key',
    storage,
    whitelist: ['navigation'],
    whitelist: ['UserData'],
};

export const persistedReducer = (rootReducer) => persistReducer(persistConfig, rootReducer);