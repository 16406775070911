import { newAPI } from "./newAxios";

export const changeRecordDataWithJSON = async (data) => {
  const { updateData, changeRemarkData } = data;
  const { url, ...otherData } = updateData;
  const receiptFile = otherData.receiptFile;
  const frmData = new FormData();
  frmData.append("receiptFile", receiptFile);
  frmData.append("expData", JSON.stringify(otherData));
  await newAPI.post("changeRecord/add", changeRemarkData);
  const result1 = await newAPI.post(url, frmData);
  return result1;
};

export const changeRecordData = async (data) => {
  const { updateData, changeRemarkData } = data;
  const { url, ...otherData } = updateData;
  await newAPI.post("changeRecord/add", changeRemarkData);
  await newAPI.post(url, otherData);
};
