import { store1 } from "store1/store1";
import { newAPI } from "../newAxios";

//////Employeee Payrolls data
export const allEmployeesPayrollsData = async () => {
  const res = await newAPI.get("/employeePayrolls");
  return res.data;
};

///get single Employee Payroll data using Employee payrolls ID
export const singleEmployeePayrollsData = async (id) => {
  const singleEmployeePayrollRespo = await newAPI.get(
    `/employeePayrolls/${id}`
  );
  return singleEmployeePayrollRespo.data[0];
};

///Add Employee Payroll
export const addEmployeePayroll = async (data) => {
  const result = await newAPI.post("employeePayrolls/add", data);
  return result;
};

///Archive The Employee Payroll / Delete the Payroll
export const deleteEmployeePayrollsData = async (id) => {
  const result = await newAPI.delete(`employeePayrolls/${id}`);
  return result;
};

//////Payroll Master
export const allPayrollMasterData = async () => {
  const {getState} = store1;
  const {pageIndex, pageSize} = getState().PayrollData ;
  const res = await newAPI.post("/payrollMaster" , {pageIndex, pageSize});
  return res.data;
};

///get payroll details data using payroll master ID
export const singlePayrollMasterData = async (id) => {
  const singlepayrollMasterRes = await newAPI.get(`/payrollMaster/${id}`);
  const payrollDetailsByPayrollMasterIdRes = await newAPI.get(
    `/payrollDetails/payrollmaster/payrollHistory/${id}`
    // `/payrollDetails/payrollMaster/${id}`
  );
  return {
    singlePayrollMasterRes: singlepayrollMasterRes.data[0],
    payrollDetailsByPayrollMasterId: payrollDetailsByPayrollMasterIdRes.data,
  };
};

////Generate History Payroll
export const generateNewPayrollDetailsData = async () => {
  const res = await newAPI.get(`/payrollDetails/generate`);
  return res.data;
};
///Employee Payroll Detail
export const singleEmployeePayrrollDetail = async (id) => {
  const resp = await newAPI.get(`/payrollDetails/${id}`);
  return resp.data[0];
};
//Add Payroll Details
export const addSinglePayrollDetail = async (data) => {
  const result = await newAPI.post("payrollDetails/add", data);
  return result;
};
export const addPayrollMasterData = async (data) => {
  const res = await newAPI.post("/payrollMaster/add", data);
  return res;
};

export const resetDateSerial = async () => {
  await newAPI.get(`/dateSerial/reset_month`);
};
export const setNextDateSerial = async () => {
  await newAPI.get(`/dateSerial/save_next_monthr`);
};
export const addDetailsONMasterId = async (id) => {
  const addRes = await newAPI.post(`/payrollDetails/addPayrollDetails`, {
    id,
  });
  return addRes;
};

//Add or Update
export const addEmployeePaymentStatus = async (data) => {
  const result = await newAPI.post("employeePayrollPayment/add_payment_status", data);
  return result;
};

///get single Employee Payroll data using Employee ID
export const singleEmployeeAllPayrollsByEmployeeId = async (id) => {
  const respo = await newAPI.get(`/employeePayrolls/employee/${id}`);
  return respo.data;
};
//
export const getDeductionGetByPayrollDetailId = async (id) => {
  const resp = await newAPI.get(
    `/payrollDetailDeductions/payroll_detail/${id}`
  );
  return resp.data;
};
export const getDeductionGetByPayrollDetailId1 = async (ctc_payroll_id,master_payroll_id) => {
  const resp = await newAPI.get(
    `/payrollDetails/payrollmaster/${ctc_payroll_id}/${master_payroll_id}`
  );
  return resp.data;
};

export const getDeductionGetByCtcPayrollId = async (id) => {
  const resp = await newAPI.get(
    `/employeePayrollsDeduction/employee_payrolls/${id}`
  );
  return resp.data;
};
export const singleEmployeeDraftSalary = async (id) => {
  const respo = await newAPI.get(`/employeePayrolls/employeeId/${id}`);
  return respo.data[0];
};

export const employeFirstSalary = async (id) => {
  const respo = await newAPI.get(`/employeePayrolls/employeeFirstSalary/${id}`);
  return respo.data[0];
};