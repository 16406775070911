import { createSlice, createSelector } from "@reduxjs/toolkit";

//
const employeesDataSlice = createSlice({
  name: "employeesDataSliceName",
  initialState: {
    isLoading: false,
    employeesData: [],
    employeeDataWithPage: [],
    pageIndex: 1,
    pageSize: 10,
    employeeAllData: {
      employeeAddress: {},
      employeeAccount: [],
      employeeDocument: [],
      employeeEducationList: [],
      employeeWorkExperienceList: [],
      employeeLeaves: [],
      employeeBasicAndPersonal: {},
      
    },

    loaderMessage: "",
    employeeOnlyBasicData: {},
  },
  reducers: {
    setPage(state, action) {
      state.pageIndex = action.payload;
    },
    setRowsPerPage(state, action) {
      state.pageSize = action.payload;
    },
    startEmployeesDataFetch(state, action) {
      state.isLoading = true;
      state.loaderMessage = "Loading please wait...";
    },
    setEmployeesData(state, action) {
      state.employeesData = action.payload;
    },
    setEmployeesDataWithPage(state, action) {
      state.employeeDataWithPage = action.payload;
    },
    setEmployeesDataWithFilter(state, action) {
      state.employeeDataWithPage = action.payload;
    },
    startEmployeeDataByPageIndex(state, action) {
      state.isLoading = true;
      state.loaderMessage = "Loading please wait...";
    },
    startEmployeeDataByFilter(state, action) {
      state.isLoading = true;
      state.loaderMessage = "Loading please wait...";
    },

    startEmployeeDataFecth(state, action) {
      state.isLoading = true;
      state.loaderMessage = "Loading please wait...";
    },
    setEmployeeData(state, action) {
      state.employeeAllData = action.payload;
    },
    startEmployeeOnlyBasicFecth(state, action) {
      state.isLoading = true;
      state.loaderMessage = "Loading please wait...";
    },
    setEmployeeOnlyBasicData(state, action) {
      if (action.payload) {
        state.employeeOnlyBasicData = action.payload;
      }
    },
    ///////
    startEmployeeAddressFetch(state, action) {
      state.isLoading = true;
      state.loaderMessage = "Loading please wait...";
    },
    setEmployeeAddress(state, action) {

      state.employeeAllData["employeeAddress"] = action.payload;
    },
    startEmployeeAccountFetch(state, action) {
      state.isLoading = true;
      state.loaderMessage = "Loading please wait...";
    },
    setEmployeeAccount(state, action) {

      state.employeeAllData["employeeAccount"] = action.payload;
    },
    startEmployeeDocumentFetch(state, action) {
      state.isLoading = true;
      state.loaderMessage = "Loading please wait...";
    },
    setEmployeeDocument(state, action) {

      state.employeeAllData["employeeDocument"] = action.payload;
    },
    startEmployeeEducationFetch(state, action) {
      state.isLoading = true;
      state.loaderMessage = "Loading please wait...";
    },
    setEmployeeEducation(state, action) {

      state.employeeAllData["employeeEducationList"] = action.payload;
    },
    startEmployeeExperienceFetch(state, action) {
      state.isLoading = true;
      state.loaderMessage = "Loading please wait...";
    },
    setEmployeeExperience(state, action) {

      state.employeeAllData["employeeWorkExperienceList"] = action.payload;
    },

    startEmployeeLeavesFetch(state, action) {
      state.isLoading = true;
      state.loaderMessage = "Loading please wait...";
    },
    setEmployeeLeaves(state, action) {
      state.employeeAllData["employeeLeaves"] = action.payload;
    },

    //////

    completedFetch(state, action) {
      state.isLoading = false;
    },
    startAddOrEditEmployee(state, action) {
      state.isLoading = true;
      state.loaderMessage = "Loading please wait...";
    },
    startAddorEditEmployeeAddress(state, action) {
      state.isLoading = true;
      state.loaderMessage = "Loading please wait...";
    },
    startAddorEditOfEmployeeAccount(state, action) {
      state.isLoading = true;
      state.loaderMessage = "Loading please wait...";
    },
    startAddorEditOfEmployeeEducation(state, action) {
      state.isLoading = true;
      state.loaderMessage = "Loading please wait...";
    },
    startAddorEditOfEmployeeExperience(state, action) {
      state.isLoading = true;
      state.loaderMessage = "Loading please wait...";
    },
    startAddorEditOfEmployeeDocument(state, action) {
      state.isLoading = true;
      state.loaderMessage = "Loading please wait...";
    },
    startAddorEditOfEmployeePayroll(state, action) {
      state.isLoading = true;
      state.loaderMessage = "Loading please wait...";
    },
    startDeleteEmployeeEducation(state, action) {
      state.isLoading = true;
      state.loaderMessage = "Loading please wait...";
    },
    startDeleteEmployeeExperience(state, action) {
      state.isLoading = true;
      state.loaderMessage = "Loading please wait...";
    },
    startDeleteEmployeeDocument(state, action) {
      state.isLoading = true;
      state.loaderMessage = "Loading please wait...";
    },
    startDeleteEmployeePayroll(state, action) {
      state.isLoading = true;
      state.loaderMessage = "Loading please wait...";
    },
    startDeleteEmployee(state, action) {
      state.isLoading = true;
      state.loaderMessage = "Loading please wait...";
    },
  },
});
//
export const {
  startEmployeeLeavesFetch,
  setEmployeeLeaves,
  startEmployeeExperienceFetch,
  setEmployeeExperience,
  startEmployeeEducationFetch,
  setEmployeeEducation,
  startEmployeeDocumentFetch,
  setEmployeeDocument,
  startEmployeeAccountFetch,
  setEmployeeAccount,
  startEmployeeAddressFetch,
  setEmployeeAddress,
  startEmployeesDataFetch,
  setRowsPerPage,
  setPage,
  startEmployeeDataByPageIndex,
  startEmployeeDataByFilter,
  setEmployeesData,
  setEmployeesDataWithPage,
  setEmployeesDataWithFilter,
  startEmployeeOnlyBasicFecth,
  setEmployeeOnlyBasicData,
  startEmployeeDataFecth,
  setEmployeeData,
  completedFetch,
  startAddOrEditEmployee,
  startAddorEditEmployeeAddress,
  successOfAddOrEdit,
  setErrorOfAddEdit,
  resetSnackbar,
  startDeleteEmployee,
  startAddorEditOfEmployeeAccount,
  startAddorEditOfEmployeeEducation,
  startAddorEditOfEmployeeExperience,
  startDeleteEmployeeEducation,
  startDeleteEmployeeExperience,
  startAddorEditOfEmployeeDocument,
  startDeleteEmployeeDocument,
  startAddorEditOfEmployeePayroll,
  startDeleteEmployeePayroll,
} = employeesDataSlice.actions;

export default employeesDataSlice.reducer;

////////////////////////////////////////////////////////////
///selector
const mainEmployeeDataState = (state) => state.EmployeesData;
///Data Loading Selector
export const isLoadingSelector = createSelector(
  [mainEmployeeDataState],
  (employeeDataState) => employeeDataState.isLoading
);
//All Employee Data
export const allEmployeesDataSelector = createSelector(
  [mainEmployeeDataState],
  (employeeDataState) => employeeDataState.employeesData
);
//pagination
export const employeesPageDataSelector = createSelector(
  [mainEmployeeDataState],
  (employeeDataState) => employeeDataState.employeeDataWithPage
);
//Single Data Selector
export const singleEmployeeDataSelector = createSelector(
  [mainEmployeeDataState],
  (employeeDataState) => employeeDataState.employeeAllData
);
export const singleEmployeeOnlyBasicDataSelector = createSelector(
  [mainEmployeeDataState],
  (employeeDataState) => employeeDataState.employeeOnlyBasicData
);
//

export const loaderMessageSelector = createSelector(
  [mainEmployeeDataState],
  (employeeDataState) => employeeDataState.loaderMessage
);
export const pageIndexSelector = createSelector(
  [mainEmployeeDataState],
  (employeeDataState) => employeeDataState.pageIndex
);
export const pageSizeSelector = createSelector(
  [mainEmployeeDataState],
  (employeeDataState) => employeeDataState.pageSize
);

