import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";

const clientReportDataSlice = createSlice({
  name: "reportsDataSliceName",
  initialState: {
    isLoading: false,
    singleClientReports: [],
  },
  reducers: {
    startSingleClientReportData(state, action) {
      state.isLoading = true;
    },
    setSingleClientReportData(state, action) {
      state.singleClientReports = action.payload;
    },
    completedFetch(state, action) {
      state.isLoading = false;
    },
  },
});

export const {
  startSingleClientReportData,
  setSingleClientReportData,
  completedFetch,
} = clientReportDataSlice.actions;
//
export default clientReportDataSlice.reducer;

///////////////////////////////////////////////////////////////
///selector
const mainClientReportState = (state) => state.ClientReportData;
//
export const isLoadingSelector = createSelector(
  [mainClientReportState],
  (clientReportDataState) => clientReportDataState.isLoading
);
//
export const reportOfClientDataSelector = createSelector(
  [mainClientReportState],
  (clientReportDataState) => clientReportDataState.singleClientReports
);
//
