import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";

///
const paymentDataSlice = createSlice({
  name: "paymentDataSlice",
  initialState: {
    isLoading: false,
    allPaymentsData: [],
    allPaymentInvoices: [],
    clientPaymentsData: {},
    // clientPaymentsData: [],
    clientPaymentDetails: [],
    errorMessage: "",
    pageIndex: 1,
    pageSize: 10,
    backDrop: false,
    createPram: null,
  },
  reducers: {
    setPage(state, action) {
      state.pageIndex = action.payload;
    },
    setRowsPerPage(state, action) {
      state.pageSize = action.payload;
    },
    startAllPayments(state, action) {
      state.isLoading = true;
    },
    startPaymentListByPageIndex(state, action) {
      state.isLoading = true;
    },
    startFilterpaymentsListData(state, action) {
      state.isLoading = true;
    },

    setAllPaymentsData(state, action) {
      state.allPaymentsData = action.payload;
    },
    setAllPaymentInvoices(state, action) {
      state.allPaymentInvoices = action.payload;
    },
    startClientPaymentsData(state, action) {
      state.isLoading = true;
    },
    startClientPaymentsDataNoLoad(state, action) {
      state.isLoading = false;
    },
    startDeletePayInvDetails(state, action) {
      state.backDrop = true;
    },
    setClientPaymentsData(state, action) {
      state.clientPaymentsData = action.payload;
    },
    setClientPaymentDetails(state, action) {
      state.clientPaymentDetails = action.payload;
    },
    startAddNewPayment(state, action) {
      state.isLoading = true;
    },
    startSinglePaymentDetailsByPaymentNumber(state, action) {
      state.isLoading = true;
    },
    startDeletePayment(state, action) {
      state.isLoading = true;
    },
    startAddingPaymentDetails(state, action) {
      state.isLoading = true;
    },
    completedFetch(state, action) {
      state.isLoading = false;
    },
    setCreatePrams(state, action) {
      state.createPram = action.payload;
    },
  },
});
//
export const {
  startAllPayments,
  startPaymentListByPageIndex,
  startFilterpaymentsListData,
  startAddingPaymentDetails,
  startSinglePaymentDetailsByPaymentNumber,
  startDeletePayInvDetails,
  setAllPaymentsData,
  setAllPaymentInvoices,
  startClientPaymentsData,
  startClientPaymentsDataNoLoad,
  setClientPaymentsData,
  setClientPaymentDetails,
  completedFetch,
  startAddNewPayment,
  resetSnackbar,
  setErrorOfAddEdit,
  successOfAddEdit,
  startDeletePayment,
  setPage,
  setCreatePrams,
  setRowsPerPage
} = paymentDataSlice.actions;
//
export default paymentDataSlice.reducer;

///Selector
const mainRootReducerState = (state) => state.PaymentsData;
//
export const isLoadingSelector = createSelector(
  [mainRootReducerState],
  (paymentStore) => paymentStore.isLoading
);
//
export const allPaymentsDataSelector = createSelector(
  [mainRootReducerState],
  (paymentStore) => paymentStore.allPaymentsData
);
//
export const allPaymentsInvoicesSelector = createSelector(
  [mainRootReducerState],
  (paymentStore) => paymentStore.allPaymentInvoices
);
//
export const singleClientPaymentsSelector = createSelector(
  [mainRootReducerState],
  (paymentStore) => paymentStore.clientPaymentsData
);
///
export const createPramsPaymentSelector = createSelector(
  [mainRootReducerState],
  (paymentStore) => paymentStore.createPram
);
///
export const singleClientPaymentsDetailSelector = createSelector(
  [mainRootReducerState],
  (paymentStore) => paymentStore.clientPaymentDetails
);
///
export const rowsPerPageSelector = createSelector(
  [mainRootReducerState],
  (paymentStore) => paymentStore.pageSize
);

export const pageSelector = createSelector(
  [mainRootReducerState],
  (paymentStore) => paymentStore.pageIndex
);

