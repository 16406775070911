import { store1 } from "../../store1/store1";
import { newAPI } from "../newAxios";

export const clientData = async () => {
  const res = await newAPI.get("/client");
  
  return res.data;
};

export const clientDataByPageIndex = async () => {
  const {getState} = store1;
  const {pageIndex, pageSize} = getState().ClientsData;
  const res = await newAPI.post("/client", {pageIndex, pageSize});
  return res.data;
};

export const clientFilterData = async (data) => {
  const {getState} = store1;
  const {pageIndex, pageSize} = getState().ClientsData;
  const itemobj = {
    pageIndex: pageIndex,
    pageSize: pageSize,
    clientName: data.clientName,
    officeEmailId: data.officeEmailId,
    officePhone: data.officePhone
  };
  const res = await newAPI.post("/client/dynamic-filter", itemobj);
  return res.data;
};

export const singleClientData = async (id) => {
  const res = await newAPI.get(`/client/${id}`);
  return res.data[0];
};

export const addOrEditClientData = async (data) => {
  const result = await newAPI.post("client/add", data);
  
  return result;
};

export const deleteClientData = async (id) => {
  const result = await newAPI.delete(`client/${id}`);
  return result;
};
