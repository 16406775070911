import { takeLatest, all, call, put } from "redux-saga/effects";
import {
  startAllPayments,
  setAllPaymentsData,
  startClientPaymentsData,
  setClientPaymentsData,
  completedFetch,
  startAddNewPayment,
  startDeletePayment,
  startPaymentListByPageIndex,
  startFilterpaymentsListData,
  setClientPaymentDetails,
  startSinglePaymentDetailsByPaymentNumber,
  setCreatePrams,
  startAddingPaymentDetails,
  startDeletePayInvDetails,
  setAllPaymentInvoices,
  startFilterpaymentsListDataWithoutLoad,
  startClientPaymentsDataNoLoad
} from "./paymentData.slice.selector";
//apis
import {
  paymentsData,
  paymentsDataByPageIndex,
  addNewPaymentData,
  deletePaymentData,
  addInvoicePaymentsDetails,
  singleClientPaymentsData,
  paymentsDataFilter,
  singleClientPaymentsDetails,
  deletePaymentInvoiceData,
  filterInvoicePaymentsDetails
} from "../../Api/PaymentsClientAPI/paymentsClientAPI";
import { setNotificationAction } from "../NotificationStore/notification.slice.selector";
//
//watcher //All Payment Data (1)
function* startPaymentDataFetchWatcher() {
  yield takeLatest(startAllPayments.type, setPaymentsToStore);
}
/// (1)
function* setPaymentsToStore({ payload }) {
  try {
    let allPaymentData;
      allPaymentData = yield call(paymentsData, payload);
      yield put(setAllPaymentsData(allPaymentData));
    yield put(completedFetch());
  } catch (error) {
    yield put(
      setNotificationAction({
        message: error.message,
        variant: "error",
      })
    );
    yield put(completedFetch());
  }
}

///(1.1) with filter
function* startFilterPaymentListData() {
  yield takeLatest(startFilterpaymentsListData.type, filterpaymentsstore);
}
///(1.1)
function* filterpaymentsstore({ payload }) {
  try {
    let allPaymentData
    if(payload?.id){
      allPaymentData = yield call(filterInvoicePaymentsDetails, payload);
      yield put(setAllPaymentInvoices(allPaymentData));
    } else{
      allPaymentData = yield call(paymentsDataFilter, payload);
      yield put(setAllPaymentsData(allPaymentData));
    }
    yield put(completedFetch());

  } catch (error) {
    yield put(
      setNotificationAction({
        message: error.message,
        variant: "error",
      })
    );
    yield put(completedFetch());
  }
}

///(1.2)With Pagination
function* startPaymentsListDataByPageIndex() {
  yield takeLatest(startPaymentListByPageIndex.type, setPaymentPagination);
}

function* setPaymentPagination(){
  try {
    let allPaymentData
    allPaymentData = yield call(paymentsDataByPageIndex);
    yield put(setAllPaymentsData(allPaymentData));
    yield put(completedFetch());

  } catch (error) {
    yield put(
      setNotificationAction({
        message: error.message,
        variant: "error",
      })
    );
    yield put(completedFetch());
  }
}
//watcher Single Payment Data (2)
function* startSinglePaymentFetchWatcher() {
  yield takeLatest(startClientPaymentsData.type, setPaymentToStore);
}
function* startSinglePaymentFetchWatcherLoad() {
  yield takeLatest(startClientPaymentsDataNoLoad.type, setPaymentToStore);
}
//(2)
function* setPaymentToStore({ payload }) {
  try {
    const singleEmployeeData = yield call(singleClientPaymentsData , payload);
    const singleEmployeeDetails = yield call(singleClientPaymentsDetails , payload);
    yield put(setClientPaymentsData(singleEmployeeData));
    yield put(setClientPaymentDetails(singleEmployeeDetails));
    yield put(completedFetch());
  } catch (error) {
    yield put(
      setNotificationAction({
        message: error.message,
        variant: "error",
      })
    );
    yield put(completedFetch());
  }
}


//(3) Watcher For Add Invoice
function* startAddPayment() {
  yield takeLatest(startAddNewPayment.type, setupAddNewPayment);
}
//(3)Next
function* setupAddNewPayment({ payload }) {
  try {
    if (payload.id) {
      yield call(addNewPaymentData, payload.data);
      yield put(startSinglePaymentDetailsByPaymentNumber(payload.id));
    } else {
      const result = yield call(addNewPaymentData, payload.data);
      yield put(setCreatePrams(result.data.insertId));
    }

    yield put(startPaymentListByPageIndex(payload.tableData));
    yield put(completedFetch());
  } catch (error) {
    yield put(
      setNotificationAction({
        message: error.message,
        variant: "error",
      })
    );
    yield put(completedFetch());
  }
}
//(5)Watcher
function* startPaymentDelete() {
  yield takeLatest(startDeletePayment.type, setUpForDelete);
}
//(5)
function* setUpForDelete({ payload }) {
  try {
    yield call(deletePaymentData, payload.data);
    yield put(startPaymentListByPageIndex(payload.tableData));
    yield put(
      setNotificationAction({
        message: "Successfully Deleted",
        variant: "success",
      })
    );
  } catch (error) {
    yield put(
      setNotificationAction({
        message: error.message,
        variant: "error",
      })
    );
    yield put(completedFetch());
  }
}

//(6)Watcher
function* startAddDetailsOfPayment() {
  yield takeLatest(startAddingPaymentDetails.type, setupAddDetailsOfPayment);
}
//(6)Next
function* setupAddDetailsOfPayment({ payload }) {
  try {
    yield call(addInvoicePaymentsDetails, payload);
    yield put(startSinglePaymentDetailsByPaymentNumber(payload.paymentId));
    yield put(
      setNotificationAction({
        message: "Successfully Added",
        variant: "success",
      })
    );
    yield put(completedFetch());
  } catch (error) {
    yield put(
      setNotificationAction({
        message: error.message,
        variant: "error",
      })
    );
    yield put(completedFetch());
  }
}
function* startPaymentDetailsDelete() {
  yield takeLatest(startDeletePayInvDetails.type, setUpForPaymentDetailDelete);
}
//(6)
function* setUpForPaymentDetailDelete({ payload }) {
  const { id } = payload
  try {
    yield call(deletePaymentInvoiceData, payload);
    yield put(startSinglePaymentDetailsByPaymentNumber(id));
    yield put(
      setNotificationAction({
        message: "Successfully Deleted",
        variant: "error",
      })
    );
    yield put(completedFetch());
  } catch (error) {
    yield put(
      setNotificationAction({
        message: error.message,
        variant: "error",
      })
    );
    yield put(completedFetch());
  }
}
///Accumulator

export default function* paymentsDataSaga() {
  yield all([
    call(startPaymentDataFetchWatcher),
    call(startFilterPaymentListData),
    call(startAddPayment),
    call(startPaymentDelete),
    call(startAddDetailsOfPayment),
    call(startPaymentsListDataByPageIndex),
    call(startPaymentDetailsDelete),
    call(startSinglePaymentFetchWatcher),
    call(startSinglePaymentFetchWatcherLoad)
  ]);
}
