import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";

const countryDataSlice = createSlice({
  name: "countryDataSlice",
  initialState: {
    isLoading: false,
    allCountryData:[],
    allCountryData1:[],
    singleCountryData:{},
    backDrop: false,
    snackbarSuccess: false,
    snackbarError: false,
    errorMessage: "",
  },
  reducers: {
    startCountry(state, action) {
      state.isLoading = true;
    },
    startCountryById(state, action) {
      state.isLoading = true;
    },
    setAllCountry(state, action) {
      state.allCountryData = action.payload;
    },
    setAllCountry1(state, action) {
      state.allCountryData1 = action.payload;
    },
    setSingleCountry(state, action) {
      state.singleCountryData = action.payload;
    },
    startAddEditNewCountry(state, action) {
      state.backDrop = true;
    },
    successOfAddEdit(state, action) {
      state.backDrop = false;
      state.snackbarSuccess = true;
    },
    setErrorOfAddEdit(state, action) {
      state.backDrop = false;
      state.snackbarError = true;
      state.errorMessage = action.payload;
    },
    resetSnackbar(state, action) {
      state.snackbarSuccess = false;
      state.snackbarError = false;
      state.errorMessage = "";
    },
    startDeleteCountry(state, action) {
      state.backDrop = true;
    },
    completedFetch(state, action) {
      state.isLoading = false;
    },

  },
});
///
export const {
  startCountry,
  startCountryById,
  setAllCountry,
  setAllCountry1,
  setSingleCountry,
  startAddEditNewCountry,
  successOfAddEdit,
  setErrorOfAddEdit,
  resetSnackbar,
  startDeleteCountry,
  completedFetch
} = countryDataSlice.actions;

export default countryDataSlice.reducer;

/////Selectors
const mainCountryDataState = (state) => state.CountryData;
//
export const isLoadingSelector = createSelector(
  [mainCountryDataState],
  (countryState) => countryState.isLoading
);
//
export const allCountrySelector = createSelector(
  [mainCountryDataState],
  (countryState) => countryState.allCountryData
);
export const allCountrySelector1 = createSelector(
  [mainCountryDataState],
  (countryState) => countryState.allCountryData1
);
//
export const singleCountryByIdSelector = createSelector(
  [mainCountryDataState],
  (countryState) => countryState.singleCountryData
);
//
export const notificationSelector = createSelector(
  [mainCountryDataState],
  (countryState) => {
    return {
      backDrop: countryState.backDrop,
      snackbarSuccess: countryState.snackbarSuccess,
      snackbarError: countryState.snackbarError,
      errorMessage: countryState.errorMessage,
    };
  }
);
