import { takeLatest, all, call, put } from "redux-saga/effects";
import {
  startSingleClientReportData,
  setSingleClientReportData,
  completedFetch,
} from "./report.slice.selector";
//
import { startUserLogout } from "../user login data/user.login.data.slice.slector";
//API's
import { clientReportData ,allClientReportData} from "../../Api/ClientReportAPI/clientReportAPI";
//(1) Watcher
function* startSingleClientReport() {
  yield takeLatest(startSingleClientReportData.type, setData);
}
//(1) Next
function* setData({ payload }) {
  try {
    if(payload){
      const allData = yield call(clientReportData, payload);
      yield put(setSingleClientReportData(allData));
      yield put(completedFetch());
    }else{
      const allData = yield call(allClientReportData);
      yield put(setSingleClientReportData(allData));
      yield put(completedFetch());
    }
    
  
  } catch (error) {
   alert(error.message , "report.saga")
   
  }
}
///Accumulator
export default function* reportOfClientSaga() {
  yield all([call(startSingleClientReport)]);
}
