import { createSlice, createSelector } from "@reduxjs/toolkit";
const notificationStoreSlice = createSlice({
  name: "notificationStoreSlice",
  initialState: {
    notificationDetails: {
        message: "",
        varient : ""
    },
  },
  reducers: {
    setNotificationAction(state, action) {
      state.notificationDetails = action.payload;
    },
  },
});

export const { setNotificationAction } = notificationStoreSlice.actions;
export default notificationStoreSlice.reducer;


///selector
const mainReducer = (state) => state.Notification;

export const notificationDetailsSelector = createSelector(
  [mainReducer],
  (myReducerStore) => myReducerStore.notificationDetails
);
///Options
export const notificationMessage = {
    success :{
        message : "Action Successfull",
        varient : "success"
    },
    error :{
        message : "Action Successfull",
        varient : "error"
    }
}