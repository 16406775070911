//TOOLKIt
import { configureStore } from '@reduxjs/toolkit';
//middlewares
import createSagaMiddleware from 'redux-saga';
//ROOT-SAGA
import rootSaga from './rootSaga';
//REDUX PERSIST
import persistStore from 'redux-persist/es/persistStore';
import { combineReducers } from 'redux';
import { persistedReducer } from './persistConfig';

//Reducer Employees
import employeesReducer from './employees data/employeesData.slice.selector';
import paymentReducer from './payment/paymentData.slice.selector';
import clientDataReducer from './client data/client.data.slice.selector';

import vendorDataReducer from './vendor data/vendor.data.slice.selector';
import invoiceDataReducer from './invoice data/invoiceData.slice.selector';
import companyDataReducer from './company master data/companyData.slice.selector';
import expenseDataReducer from './expense data/expenseData.slice.selector';
import clientReportsDataReducer from './client Report data/report.slice.selector';
import lookupDetailsReducer from './lookupDetails data/lookupDetailsSlice.selector';
import userLoginDataReducer from './user login data/user.login.data.slice.slector';
import leavesDataSliceReducer from './leaves data/leavesData.Slice.Selector';
import countryDataSliceReducer from './country data/countryDataSlice.selector';
import payrollDataSliceReducer from './payrolls data/payrollDataSlice.Selector';
import notificationSliceReducer from './NotificationStore/notification.slice.selector';
import formnavigateReducer from './formnavigation/formnavigation';
import menuReducer from './menu';
//////
const sagaMiddleware = createSagaMiddleware();
///Global Main Store
const store1 = configureStore({
    reducer: persistedReducer(
        combineReducers({
            EmployeesData: employeesReducer,
            PaymentsData: paymentReducer,
            ClientsData: clientDataReducer,
            VendorsData: vendorDataReducer,
            InvoiceData: invoiceDataReducer,
            CompanyMaster: companyDataReducer,
            ExpensesData: expenseDataReducer,
            ClientReportData: clientReportsDataReducer,
            LookupDetails: lookupDetailsReducer,
            UserData: userLoginDataReducer,
            LeavesData: leavesDataSliceReducer,
            CountryData: countryDataSliceReducer,
            PayrollData: payrollDataSliceReducer,
            Notification: notificationSliceReducer,
            Formnavigate: formnavigateReducer,
            menu: menuReducer
        })
    ),
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }).concat(sagaMiddleware)
});
const persistor = persistStore(store1);

sagaMiddleware.run(rootSaga);
// export default store1;
export { store1, persistor };
