import { newAPI } from "../newAxios";
import { store1 } from "../../store1/store1";

export const vendorData = async () => {               ////
  const res = await newAPI.get("/vendors");
  return res.data;
};

export const vendorDataByPageIndex = async () => {      ////
  const {getState} = store1;
  const {pageIndex, pageSize} = getState().VendorsData ;
  const res = await newAPI.post("/vendors", {pageIndex, pageSize});
  return res.data;
};

export const vendorFilterData = async (data) => {
  const {getState} = store1;
  const {pageIndex, pageSize} = getState().VendorsData ;
  const itemobj = {
    pageIndex: pageIndex,
    pageSize: pageSize,
    vendorName: data.vendorName,
    email: data.email,
    contactNumber: data.contactNumber
  };
  const res = await newAPI.post("/vendors/filter", itemobj);
  return res.data;
};
export const viewVendorData = async (id) => {
  const res = await newAPI.get(`/vendors/get_vendor_expenses/${id}`);
  return res.data;
};

export const singleVendorData = async (id) => {           ////
  const res = await newAPI.get(`/vendors/view_vendor/${id}`);
  return res.data[0];
};

export const vendorState = async () => {               ////
  const res = await newAPI.get("/vendors/get_state/1");
  return res.data;
};

export const addOrEditVendorData = async (data) => {          ////
  const result = await newAPI.post("vendors/add", data);
  return result;
};

export const deleteVendorData = async (id) => {                 ////
  const result = await newAPI.delete(`vendors/${id}`);
  return result;
};