import { store1 } from "../../store1/store1";
import { newAPI } from "../newAxios";

export const getAllExpensesData = async () => {
  const res = await newAPI.get("expense");
  return res.data;
};
export const getExpensesDataByPagination = async () => {
  const {getState} = store1;
  const {pageIndex, pageSize} = getState().ExpensesData
  const res = await newAPI.post("expense", {pageIndex, pageSize});
  return res.data;
};

export const getFilteredExpensesData = async (data) => {
  const {getState} = store1;
  const {pageIndex, pageSize} = getState().ExpensesData
  const payobj = {
    pageIndex: pageIndex,
    pageSize: pageSize,
    payment_by: data.payment_by,
    employee_id: data.employee_id,
    expense_status: data.expense_status,
    startDateValue: data.startDateValue,
    endDateValue: data.endDateValue
  };
  const res = await newAPI.post("expense/dynamic-filter", payobj);
  return res.data;
};
export const getSingleExpenseData = async (id) => {
  const res = await newAPI.get(`expense/${id}`);
  return res.data[0];
};

export const addExpenseMethod = async (data) => {
  const receiptFile = data.receiptFile;
  const frmData = new FormData();
  frmData.append("receiptFile", receiptFile);
  frmData.append("expData", JSON.stringify(data));
  const result = await newAPI.post("expense/add", frmData);
  return result;
};

export const deleteExpenseData = async (id) => {
  const result = await newAPI.delete(`expense/${id}`);
  return result;
};

export const getEmployeeExpenseData = async (id) => {
  const res = await newAPI.get(`expense/employee/${id}`);
  return res.data;
};

export const getPieChartData = async (data) => {
  const res = await newAPI.post("expense/all-filters", data);
  return res["data"];
};

export const getAllPieChartData = async (data) => {
  const res = await newAPI.post("expense/all-filters", data);
  return res["data"];
};

export const getPieChartDataGroupByPaymentBy = async (data) => {
  const res = await newAPI.post("expense/reportGroupByPaymentBy", data);
  return res["data"];
};

export const getExpenseDetailsDataByExpenseType = async (data) => {
  const res = await newAPI.post("expense/reportGroupByExpenseBy", data);
  return res["data"];
};
