import { newAPI } from "../newAxios";


export const clientReportData = async (id) => {

  const res = await newAPI.get(`report/cutomerAccount/${id}`);
  return res.data;
};


export const allClientReportData = async () => {

  const res = await newAPI.get(`report/cutomerAccount`);
  return res.data;
};