import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";

const expenseDataSlice = createSlice({
  name: "paymentDataSlice",
  initialState: {
    isLoading: false,
    allExpensesData: [],
    singleExpense: {},
    employeeExpense: [],
    pieChartData: [],
    expenseDetailsData: [],
    backDrop: false,
    snackbarSuccess: false,
    snackbarError: false,
    errorMessage: "",
    pageIndex: 1,
    pageSize: 10,
  },
  reducers: {
    setPage(state, action) {
      state.pageIndex = action.payload;
    },
    setRowsPerPage(state, action) {
      state.pageSize = action.payload;
    },
    startAllExpenses(state, action) {
      state.isLoading = true;
    },
    startExpenseDataByPagination(state, action) {
      state.isLoading = true;
    },
    startFilteredExpenseData(state, action) {
      state.isLoading = true;
    },
    setAllExpensesData(state, action) {
      state.allExpensesData = action.payload;
    },
    startSingleExpense(state, action) {
      state.isLoading = true;
    },
    startPieChartData(state, action) {
      state.isLoading = true;
    },
    startAllPieChartData(state, action) {
      state.isLoading = true;
    },
    startPieChartDataByPaymentBy(state, action) {
      state.isLoading = true;
    },
    startExpenseRestore(state, action) {
      state.backDrop = true;
    },
    startExpenseDetails(state, action) {
      state.isLoading = true;
    },
    setExpenseDetailsData(state, action) {
      state.expenseDetailsData = action.payload;
    },
    setPieCharDataExpense(state, action) {
      state.pieChartData = action.payload;
    },
    setsingleExpense(state, action) {
      state.singleExpense = action.payload;
    },
    startEmployeeExpense(state, action) {
      state.isLoading = true;
    },

    setEmployeeExpense(state, action) {
      state.employeeExpense = action.payload;
    },
    startAddEditNewExpense(state, action) {
      state.backDrop = true;
      state.isLoading = true;
    },
    startFilteredExpenseData(state, action) {
      state.backDrop = true;
    },
    successOfAddEdit(state, action) {
      state.backDrop = false;
      state.snackbarSuccess = true;
    },
    setErrorOfAddEdit(state, action) {
      state.backDrop = false;
      state.snackbarError = true;
      state.errorMessage = action.payload;
    },
    resetSnackbar(state, action) {
      state.snackbarSuccess = false;
      state.snackbarError = false;
      state.errorMessage = "";
    },
    startDeleteExpense(state, action) {
      state.backDrop = true;
    },
    completedFetch(state, action) {
      state.isLoading = false;
    },
  },
});
///
export const {
  startAllExpenses,
  startExpenseDataByPagination,
  startFilteredExpenseData,
  setAllExpensesData,
  startSingleExpense,
  startPieChartData,
  startAllPieChartData,
  startPieChartDataByPaymentBy,
  startExpenseDetails,
  startExpenseRestore,
  setPieCharDataExpense,
  setsingleExpense,
  completedFetch,
  resetSnackbar,
  setErrorOfAddEdit,
  successOfAddEdit,
  startAddEditNewExpense,
  startDeleteExpense,
  startEmployeeExpense,
  setEmployeeExpense,
  setExpenseDetailsData,
  setPage,
  setRowsPerPage
} = expenseDataSlice.actions;

export default expenseDataSlice.reducer;

/////Selectors
const mainExpensesState = (state) => state.ExpensesData;
//
export const isLoadingSelector = createSelector(
  [mainExpensesState],
  (expenseState) => expenseState.isLoading
);
//
export const allExpensesSelector = createSelector(
  [mainExpensesState],
  (expenseState) => expenseState.allExpensesData
);
//
export const singleExpenseSelector = createSelector(
  [mainExpensesState],
  (expenseState) => expenseState.singleExpense
);
//
export const pieChartDataSelector = createSelector(
  [mainExpensesState],
  (expenseState) => expenseState.pieChartData
);
//
export const expenseDetailsDataSelector = createSelector(
  [mainExpensesState],
  (expenseState) => expenseState.expenseDetailsData
);
//
export const notificationSelector = createSelector(
  [mainExpensesState],
  (invoiceDataState) => {
    return {
      backDrop: invoiceDataState.backDrop,
      snackbarSuccess: invoiceDataState.snackbarSuccess,
      snackbarError: invoiceDataState.snackbarError,
      errorMessage: invoiceDataState.errorMessage,
    };
  }
);

//
export const singleEmployeeExpenseSelector = createSelector(
  [mainExpensesState],
  (expenseState) => expenseState.employeeExpense
);

export const pageExpenseSelector = createSelector(
  [mainExpensesState],
  (expenseState) => expenseState.pageIndex
);

export const rowsPerPageExpenseSelector = createSelector(
  [mainExpensesState],
  (expenseState) => expenseState.pageSize
);
