import { store1 } from '../store1/store1';
import axios from 'axios';
import { setUserData } from '../store1/user login data/user.login.data.slice.slector';

// export const basicAxios = axios.create({
//   baseURL: "http://localhost:5000",
//   //baseURL:"https://mis-webapi.azurewebsites.net",
//   withCredentials: true,
// });

// export const newAPI = axios.create({
//   baseURL: "http://localhost:5000",
//   //baseURL:"https://mis-webapi.azurewebsites.net",
//   withCredentials: true,
// });

// export const privateAxios = axios.create({
//   baseURL: "http://localhost:5000",
//   // baseURL:"https://mis-webapi.azurewebsites.net",
//   headers: { "Content-Type": "application/json" },
//   withCredentials: true,
// });

const myNewAPI = () => {
    return axios.create({
        // baseURL: 'http://localhost:5000',
         baseURL: "https://mis-webapi.azurewebsites.net",
        withCredentials: true
    });
};
const myBasicAxios = () => {
    return axios.create({
        //  baseURL: "http://localhost:5000",
        baseURL: 'https://mis-webapi.azurewebsites.net',
        withCredentials: true
    });
};

const myPrivateAxios = () => {
    return axios.create({
        baseURL: 'http://localhost:5000',
        // baseURL: "https://mis-webapi.azurewebsites.net",
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
    });
};
export const newAPI = myNewAPI();
export const basicAxios = myBasicAxios();
export const privateAxios = myPrivateAxios();
newAPI.interceptors.request.use(
    function (config) {
        const { getState } = store1;
        const { userToken } = getState().UserData;
        if (!config.headers['Authorization']) {
            config.headers['Authorization'] = `Bearer ${userToken}`;
        }
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);
newAPI.interceptors.response.use(
    function (response) {
        return response;
    },
    async (error) => {
        const { dispatch } = store1;
        const prevRequest = error?.config;
        if (error?.response?.status === 403 && !prevRequest?.sent) {
            try {
                prevRequest.sent = true;
                let resp = await basicAxios.get('auth/refresh', {
                    withCredentials: true
                });
                const newAccessToken = resp.data.token;
                dispatch({ type: setUserData.type, payload: resp.data });
                prevRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
                return basicAxios(prevRequest);
            } catch (error) {
                await basicAxios.get('auth/logout', {
                    withCredentials: true
                });

                return window.location.reload();
            }
        }
        return Promise.reject(error);
    }
);
