import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";

const invoiceDataSlice = createSlice({
  name: "invoiceDataSlice",
  initialState: {
    isLoading: false,
    allInvoicesData: [],
    singleInvoice: {
      invoiceData: {},
    },
    singleInvoiceDetails: [],
    clientInvoices: {
      clientAllInvoices: [],
      clientDescriptions: [],
    },
    singleInvoiceWithClient: {
      invoice: {},
      descriptions: [],
      clientData: {},
    },
    createPram: null,
    backDrop: false,
    snackbarSuccess: false,
    snackbarError: false,
    errorMessage: "",
    pageIndex: 1,
    pageSize: 10,
  },
  reducers: {
    setPage(state, action) {
      state.pageIndex = action.payload;
    },
    setRowsPerPage(state, action) {
      state.pageSize = action.payload;
    },
    startAllInvoices(state, action) {
      state.isLoading = true;
    },
    startInvoicesListDataByPageIndex(state, action) {
      state.isLoading = true;
      state.loaderMessage = "Loading please wait...";
    },
    startFilterInvoicesListData(state, action) {
      state.isLoading = true;
      state.loaderMessage = "Loading please wait...";
    },
    setAllInvoicesData(state, action) {
      state.allInvoicesData = action.payload;
    },
    startSingleInvoice(state, action) {
      state.isLoading = true;
    },
    setsingleInvoice(state, action) {
      state.singleInvoice = action.payload;
    },
    startSingleInvoiceWithClient(state, action) {
      state.isLoading = true;
    },
    startSingleInvoiceDetailsByInvoiceNumber(state, action) {
      state.isLoading = true;
    },
    setSingleInvoiceDetailsByInvoiceNumber(state, action) {
      state.singleInvoiceDetails = action.payload;
    },
    setSingleInvoiceWithClient(state, action) {
      state.singleInvoiceWithClient = action.payload;
      state.singleInvoice = {
        invoice: {},
        descriptions: [],
      };
    },
    startAddingInvoiceDetails(state, action) {
      state.backDrop = true;
    },
    startAddNewInvoice(state, action) {
      state.isLoading = true;
    },
    startAddRestoreInvoice(state, action) {
      state.isLoading = true;
    },
    successOfAddEdit(state, action) {
      state.backDrop = false;
      state.snackbarSuccess = true;
    },
    setErrorOfAddEdit(state, action) {
      state.backDrop = false;
      state.snackbarError = true;
      state.errorMessage = action.payload;
    },
    resetSnackbar(state, action) {
      state.snackbarSuccess = false;
      state.snackbarError = false;
      state.errorMessage = "";
    },
    startDeleteInvoice(state, action) {
      state.backDrop = true;
    },
    startDeleteInvoiceDetails(state, action) {
      state.backDrop = true;
    },
    completedFetch(state, action) {
      state.isLoading = false;
      state.backDrop = false;
    },
    setCreatePrams(state, action) {
      state.createPram = action.payload;
    },
  },
});
///
export const {
  startAllInvoices,
  startInvoicesListDataByPageIndex,
  startFilterInvoicesListData,
  setAllInvoicesData,
  startSingleInvoice,
  setsingleInvoice,
  completedFetch,
  startSingleInvoiceWithClient,
  startAddRestoreInvoice,
  setSingleInvoiceWithClient,
  resetSnackbar,
  setErrorOfAddEdit,
  successOfAddEdit,
  startAddingInvoiceDetails,
  startAddNewInvoice,
  startSingleInvoiceDetailsByInvoiceNumber,
  setSingleInvoiceDetailsByInvoiceNumber,
  startDeleteInvoice,
  startDeleteInvoiceDetails,
  setCreatePrams,
  setPage,
  setRowsPerPage
} = invoiceDataSlice.actions;

export default invoiceDataSlice.reducer;

/////Selectors
const mainInvoicesState = (state) => state.InvoiceData;
//
export const isLoadingSelector = createSelector(
  [mainInvoicesState],
  (invoiceState) => invoiceState.isLoading
);
//
export const allInvoicesSelector = createSelector(
  [mainInvoicesState],
  (invoiceState) => invoiceState.allInvoicesData
);
//
export const singleInvoiceSelector = createSelector(
  [mainInvoicesState],
  (invoiceState) => invoiceState.singleInvoice
);
//
export const singleInvoiceDetailsSelector = createSelector(
  [mainInvoicesState],
  (invoiceState) => invoiceState.singleInvoiceDetails
);
///
export const notificationSelector = createSelector(
  [mainInvoicesState],
  (invoiceDataState) => {
    return {
      backDrop: invoiceDataState.backDrop,
      snackbarSuccess: invoiceDataState.snackbarSuccess,
      snackbarError: invoiceDataState.snackbarError,
      errorMessage: invoiceDataState.errorMessage,
    };
  }
);
//
export const createPramsSelector = createSelector(
  [mainInvoicesState],
  (invoiceState) => invoiceState.createPram
);

export const pageIndexSelector = createSelector(
  [mainInvoicesState],
  (invoiceState) => invoiceState.pageIndex
);

export const pageSizeSelector = createSelector(
  [mainInvoicesState],
  (invoiceState) => invoiceState.pageSize
);
