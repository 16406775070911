import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";

const companyMasterSlice = createSlice({
  name: "companyMasterSliceName",
  initialState: {
    isLoading: false,
    companyMaster: {},
    backDrop: false,
    snackbarSuccess: false,
    snackbarError: false,
    errorMessage: "",
  },
  reducers: {
    startAllCompanyMaster(state, action) {
      state.isLoading = true;
    },
    setCompanyMaster(state, action) {
      state.companyMaster = action.payload;
    },
    completedFetch(state, action) {
      state.isLoading = false;
    },
    startEditCompanyMaster(state, action) {
      state.backDrop = true;
    },
    successOfAddOrEdit(state, action) {
      state.backDrop = false;
      state.snackbarSuccess = true;
    },
    setErrorOfAddEdit(state, action) {
      state.backDrop = false;
      state.snackbarError = true;
      state.errorMessage = action.payload;
    },
    resetSnackbar(state, action) {
      state.snackbarSuccess = false;
      state.snackbarError = false;
      state.errorMessage = "";
    },
  },
});

///
export const {
  startAllCompanyMaster,
  setCompanyMaster,
  completedFetch,
  startEditCompanyMaster,
  successOfAddOrEdit,
  setErrorOfAddEdit,
  resetSnackbar,
} = companyMasterSlice.actions;
///
export default companyMasterSlice.reducer;

/////Selectors
const mainCompanyMaster = (state) => state.CompanyMaster;
/////
export const isLoadingSelector = createSelector(
  [mainCompanyMaster],
  (companyState) => companyState.isLoading
);
/////
export const companyMasterSelector = createSelector(
  [mainCompanyMaster],
  (companyState) => companyState.companyMaster
);
/////
export const notificationSelector = createSelector(
  [mainCompanyMaster],
  (companyMasterDataState) => {
    return {
      backDrop: companyMasterDataState.backDrop,
      snackbarSuccess: companyMasterDataState.snackbarSuccess,
      snackbarError: companyMasterDataState.snackbarError,
      errorMessage: companyMasterDataState.errorMessage,
    };
  }
);
