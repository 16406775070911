import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";

const leavesDataSlice = createSlice({
  name: "leavesDataSliceName",
  initialState: {
    isLoading: false,
    allLeavesData: [],
    overLappedLeaves: [],
    overlappingLoading: false,
    employeeLeaveSummaryDetails: [],
    employeesListLeaveSummary: [],
    employeeAllLeaves: {
      employeeLeaveSummary: [],
      employeeLeavesLsit: [],
    },
    singleLeaveData: {},
    backDrop: false,
    snackbarSuccess: false,
    snackbarError: false,
    errorMessage: "",
  },
  reducers: {
    startAllLeavesData(state, action) {
      state.isLoading = true;
    },
    setAllLeavesData(state, action) {
      state.allLeavesData = action.payload;
    },

    startFindingOverlappingDates(state, action) {
      state.overlappingLoading = true;
    },

    setLeavesOverlapping(state, action) {
      state.overLappedLeaves = action.payload;
    },

    startEmployeeeLeaveSummaryDetails(state, action) {
      state.isLoading = true;
    },
    setEmployeesListLeaveSummary(state, action) {
      state.employeesListLeaveSummary = action.payload;
    },
    setEmployeeeLeaveSummaryDetails(state, action) {
      state.employeeLeaveSummaryDetails = action.payload;
    },
    startEmployeeLeavesData(state, action) {
      state.isLoading = true;
    },
    setEmployeeLeavesData(state, action) {
      state.employeeAllLeaves = action.payload;
    },
    startSingleLeaveData(state, action) {
      state.isLoading = true;
    },
    startAddRestoreLeave(state, action) {
      state.isLoading = true;
    },
    setSingleLeaveData(state, action) {
      state.singleLeaveData = action.payload;
    },
    startAddOrApproveEditLeave(state, action) {
      state.isLoading = true;
    },
    successOfAddOrEdit(state, action) {
      state.isLoading = false;
    },
    setErrorOfAddEdit(state, action) {
      state.isLoading = false;
    },
    resetSnackbar(state, action) {
      state.snackbarSuccess = false;
      state.snackbarError = false;
      state.errorMessage = "";
    },
    startDeleteLeave(state, action) {
      state.isLoading = true;
    },
    completedFetch(state, action) {
      state.isLoading = false;
      state.overlappingLoading = false;
    },
  },
});
//
export const {
  startAllLeavesData,
  setAllLeavesData,
  startSingleLeaveData,
  setSingleLeaveData,
  startEmployeeeLeaveSummaryDetails,
  setEmployeeeLeaveSummaryDetails,
  setEmployeesListLeaveSummary,
  startAddOrApproveEditLeave,
  startFindingOverlappingDates,
  setLeavesOverlapping,
  setErrorOfAddEdit,
  resetSnackbar,
  successOfAddOrEdit,
  startDeleteLeave,
  startEmployeeLeavesData,
  setEmployeeLeavesData,
  completedFetch,
  startAddRestoreLeave,
} = leavesDataSlice.actions;
export default leavesDataSlice.reducer;

///////////////////////////////////////////////////////////////
///selector
const mainLeaveState = (state) => state.LeavesData;
//
export const isLoadingSelectorLeave = createSelector(
  [mainLeaveState],
  (leaveDataState) => leaveDataState.isLoading
);
//
export const allLeavesDataSelector = createSelector(
  [mainLeaveState],
  (leaveDataState) => leaveDataState.allLeavesData
);
//
export const employeeAllLeavesDataSelector = createSelector(
  [mainLeaveState],
  (leaveDataState) => leaveDataState.employeeAllLeaves
);
//
export const leaveDataSelector = createSelector(
  [mainLeaveState],
  (leaveDataState) => leaveDataState.singleLeaveData
);
//
export const overlappingLoadingSelector = createSelector(
  [mainLeaveState],
  (leaveDataState) => leaveDataState.overlappingLoading
);

///employeeLeaveSummaryDetails
export const leaveOverlappedDataSelector = createSelector(
  [mainLeaveState],
  (leaveDataState) => leaveDataState.overLappedLeaves
);
//
export const employeeLeaveSummaryDetailsSelector = createSelector(
  [mainLeaveState],
  (leaveDataState) => leaveDataState.employeeLeaveSummaryDetails
);
export const employeesListLeaveSummarySelector = createSelector(
  [mainLeaveState],
  (leaveDataState) => leaveDataState.employeesListLeaveSummary
);
//
export const notificationSelector = createSelector(
  [mainLeaveState],
  (leaveDataState) => {
    return {
      backDrop: leaveDataState.backDrop,
      snackbarSuccess: leaveDataState.snackbarSuccess,
      snackbarError: leaveDataState.snackbarError,
      errorMessage: leaveDataState.errorMessage,
    };
  }
);
