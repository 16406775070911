import { takeLatest, all, call, put } from "redux-saga/effects";
import {
  startAllCompanyMaster,
  setCompanyMaster,
  completedFetch,
  startEditCompanyMaster,
  successOfAddOrEdit,
  setErrorOfAddEdit,
  resetSnackbar,
} from "./companyData.slice.selector";
//
import { startUserLogout } from "../user login data/user.login.data.slice.slector";
///API's
import { companyMaster, companyMasterPost } from "../../Api/CompanyMasterAPI/companyMasterAPI";
//

////(1) Watcher for All Invoices
function* startAllCompany() {
  yield takeLatest(startAllCompanyMaster.type, setAllCompanyMaster);
}
//(1) Next
function* setAllCompanyMaster() {
  try {
    const invoicesData = yield call(companyMaster);
    yield put(setCompanyMaster(invoicesData));
    yield put(completedFetch());
  } catch (error) {
    if (error.message === "Request failed with status code 403") {
      yield put(startUserLogout());
    }
  }
}
//(2)Watcher
function* startEditMaster() {
  yield takeLatest(startEditCompanyMaster.type, editCompanyMaster);
}
//(2)Next
function* editCompanyMaster({ payload }) {
  try {
    const result = yield call(companyMasterPost, payload);
    yield put(successOfAddOrEdit());
  } catch (error) {
    if (error.message === "Request failed with status code 403") {
      yield put(startUserLogout());
    }
    yield put(setErrorOfAddEdit(error.response.data));
  }
}
///Accumulator
export default function* companyMasterDataSaga() {
  yield all([call(startAllCompany), call(startEditMaster)]);
}
