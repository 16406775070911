import { newAPI } from "../newAxios";
import { store1 } from "../../store1/store1";

export const allInvoiceData = async () => {
  const res = await newAPI.get("/invoice");
  return res.data;
};
export const invoiceListDataByPageIndex = async (data) => {
  const {getState} = store1;
  const {pageIndex, pageSize} = getState().InvoiceData;
  const res = await newAPI.post("/invoice", {pageIndex, pageSize});
  return res.data;
};
export const invoiceFilterListData = async (data) => {
  const {getState} = store1;
  const {pageIndex, pageSize} = getState().InvoiceData;
  const itemobj = {
    pageIndex: pageIndex,
    pageSize: pageSize,
    clientName: data.clientName,
    invoiceStatus: data.invoiceStatus,
    invoiceNumber: data.invoiceNumber,
    startDateValue: data.startDateValue,
    endDateValue: data.endDateValue
  };
  const res = await newAPI.post("/invoice/dynamic-filter", itemobj);
  return res.data;
};
export const singleInvoiceDataByID = async (id) => {
  const singleInvoiceRespo = await newAPI.get(`/invoice/${id}`);
  return {
    invoice: singleInvoiceRespo.data[0],
  };
};

export const addInvoiceDetailsData = async (data) => {
  const result = await newAPI.post("invoiceDetail/add", data);
  return result;
};

export const singleInvoiceDescriptionDataByInvoiceId = async (id) => {
  const singleInvoiceDetailsResponse = await newAPI.get(
    `/invoiceDetail/invoice/${id}`
  );
  return singleInvoiceDetailsResponse.data;
};

export const addNewInvoiceData = async (invoiceData) => {
  const result = await newAPI.post("invoice/add", invoiceData);
  return result;
};

export const deleteInvoiceData = async (id) => {
  const result = await newAPI.delete(`invoice/${id}`);
  
  return result;
};

export const saveNextInvoiceNumber = async () => {
  const result = await newAPI.get("invoiceFormat/save_next_number");
  return result;
};

export const deleteInvoiceDetails = async (id) => {
  const result = await newAPI.delete(`invoiceDetail/${id}`);
  
  return result;
};


export const getNextInvoiceNo = async () => {
 
  const result = await newAPI.get("invoiceFormat/next_invoice_no");
  return result.data[0];
};