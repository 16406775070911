import { takeLatest, all, call, put } from "redux-saga/effects";
import {
  startAllLeavesData,
  setAllLeavesData,
  startEmployeeeLeaveSummaryDetails,
  setEmployeeeLeaveSummaryDetails,
  setEmployeesListLeaveSummary,
  startEmployeeLeavesData,
  setEmployeeLeavesData,
  startSingleLeaveData,
  setSingleLeaveData,
  startAddOrApproveEditLeave,
  startFindingOverlappingDates,
  setLeavesOverlapping,
  startDeleteLeave,
  completedFetch,
  startAddRestoreLeave,
} from "./leavesData.Slice.Selector";
//
import { setNotificationAction } from "../NotificationStore/notification.slice.selector";

//API's
import { changeRecordData } from "../../Api/api";
import {
  leaveData,
  singleLeaveData,
  addOrEditorApproveLeave,
  deleteLeaveData,
  employeeLeavesdata,
  findTheOverLappingDates,
  findTheEmployeesleaveGroupBy,
} from "../../Api/LeaveAPI/leavesAPI";
//(1) Watcher
function* startAllLeaveData() {
  yield takeLatest(startAllLeavesData.type, setAllData);
}
//(1) Next
function* setAllData() {
  try {
    const allLeaveDatas = yield call(leaveData);
    yield put(setAllLeavesData(allLeaveDatas));
    yield put(completedFetch());
  } catch (error) {
    yield put(
      setNotificationAction({
        message: error.message,
        variant: "error",
      })
    );
    yield put(completedFetch());
  }
}
//(2) Watcher
function* startSingleData() {
  yield takeLatest(startSingleLeaveData.type, setSingleData);
}
//(2) Next
function* setSingleData({ payload }) {
  try {
    const data = yield call(singleLeaveData, payload);
    yield put(setSingleLeaveData(data));
    yield put(completedFetch());
  } catch (error) {
    yield put(
      setNotificationAction({
        message: error.message,
        variant: "error",
      })
    );
    yield put(completedFetch());
  }
}
//(3) Watcher
function* startAddEditLeaveWatcher() {
  yield takeLatest(startAddOrApproveEditLeave.type, setupLeaveDataAddEdit);
}
//(3) Next
function* setupLeaveDataAddEdit({ payload }) {
  try {
    yield call(addOrEditorApproveLeave, payload);
    yield put(startAllLeavesData());
    yield put(
      setNotificationAction({
        message: "Successfully Updated",
        variant: "success",
      })
    );
    yield put(completedFetch());
  } catch (error) {
    yield put(
      setNotificationAction({
        message: error.message,
        variant: "error",
      })
    );
    yield put(completedFetch());
  }
}
//(4)Watcher
function* startLeaveDelete() {
  yield takeLatest(startDeleteLeave.type, setUpForDelete);
}
//(4)
function* setUpForDelete({ payload }) {
  try {
    yield call(deleteLeaveData, payload);
    yield put(
      setNotificationAction({
        message: "Successfully Deleted",
        variant: "error",
      })
    );
    yield put(completedFetch());
  } catch (error) {
    yield put(
      setNotificationAction({
        message: error.message,
        variant: "error",
      })
    );
    yield put(completedFetch());
  }
}
//(5) Watcher
function* startAllEmployeeLeaveData() {
  yield takeLatest(startEmployeeLeavesData.type, setAllEmployeeLeavesData);
}
//(5) Next
function* setAllEmployeeLeavesData({ payload }) {
  try {
    const allLeaveDatas = yield call(employeeLeavesdata, payload);
    yield put(setEmployeeLeavesData(allLeaveDatas));
    yield put(completedFetch());
  } catch (error) {
    yield put(
      setNotificationAction({
        message: error.message,
        variant: "error",
      })
    );
    yield put(completedFetch());
  }
}

//(6) Watcher For Add  Leave Restore
function* start_startAddRestoreLeave() {
  yield takeLatest(startAddRestoreLeave.type, set_startAddRestoreLeave);
}
//(6)Next
function* set_startAddRestoreLeave({ payload }) {
  try {
    yield call(changeRecordData, payload);

    yield put(
      setNotificationAction({
        message: "Successfully Updated",
        variant: "success",
      })
    );
    yield put(completedFetch());
  } catch (error) {
    yield put(
      setNotificationAction({
        message: error.message,
        variant: "error",
      })
    );
    yield put(completedFetch());
  }
}

//(7) Watcher For Overlappping leaves dates
function* start_starOverlapppingLeave() {
  yield takeLatest(
    startFindingOverlappingDates.type,
    set_startOverlapppingLeave
  );
}
// startFindingOverlappingDates,
//   setLeavesOverlapping,
//(7)Next
function* set_startOverlapppingLeave({ payload }) {
  try {
    const data = yield call(findTheOverLappingDates, payload);
    yield put(setLeavesOverlapping(data));

    yield put(completedFetch());
  } catch (error) {
    yield put(
      setNotificationAction({
        message: error.message,
        variant: "error",
      })
    );
    yield put(completedFetch());
  }
}

//(8) Watcher For Overlappping leaves dates
function* start_EmployeeeLeaveSummaryDetails() {
  yield takeLatest(
    startEmployeeeLeaveSummaryDetails.type,
    set_startEmployeeeLeaveSummaryDetails
  );
}
// startFindingOverlappingDates,
//   setLeavesOverlapping,
//(8)Next
function* set_startEmployeeeLeaveSummaryDetails({ payload }) {
  try {
    const data = yield call(findTheEmployeesleaveGroupBy, payload);
    if (payload.employee_id < 0) {
      yield put(setEmployeesListLeaveSummary(data));
    } else {
      yield put(setEmployeeeLeaveSummaryDetails(data));
    }
    yield put(completedFetch());
  } catch (error) {
    yield put(
      setNotificationAction({
        message: error.message,
        variant: "error",
      })
    );
    yield put(completedFetch());
  }
}
//Accumulator
export default function* leavesDataSaga() {
  yield all([
    call(startAllLeaveData),
    call(startSingleData),
    call(startAddEditLeaveWatcher),
    call(startLeaveDelete),
    call(startAllEmployeeLeaveData),
    call(start_startAddRestoreLeave),
    call(start_starOverlapppingLeave),
    call(start_EmployeeeLeaveSummaryDetails),
  ]);
}
