///Saga Effects
import { all, call } from "redux-saga/effects";
////Sagas
import clientsDataSaga from "./client data/client.saga";
import vendorsDataSaga from "./vendor data/vendor.saga";
import employeeDataDataSaga from "./employees data/employeeData.saga";
import invoiceDataSaga from "./invoice data/invoiceData.saga";
import paymentsDataSaga from "./payment/paymentData.saga";
import companyMasterDataSaga from "./company master data/company.Data.saga";
import expensesDataSaga from "./expense data/expenseData.saga";
import reportOfClientSaga from "./client Report data/report.saga";
import lookupDetailsDataSaga from "./lookupDetails data/lookupDetails.saga";
import userDataSaga from "./user login data/user.login.saga";
import leavesDataSaga from "./leaves data/leavesSaga";
import countryDataSaga from "./country data/countrysData.saga";
import ParollSaga from "./payrolls data/payrollDataSaga";

//Default
export default function* rootSaga() {
  yield all([
    call(employeeDataDataSaga),
    call(clientsDataSaga),
    call(vendorsDataSaga),
    call(invoiceDataSaga),
    call(paymentsDataSaga),
    call(companyMasterDataSaga),
    call(expensesDataSaga),
    call(reportOfClientSaga),
    call(lookupDetailsDataSaga),
    call(userDataSaga),
    call(leavesDataSaga),
    call(countryDataSaga),
    call(ParollSaga)
  ]);
}
