import { store1 } from "store1/store1";
import { newAPI } from "../newAxios";
/////////////////////////////////////////Employees Data Part
export const BasicPersonalEmployeesData = async () => {
  const res = await newAPI.get("/employee");
  return res.data;
};
export const employeeListByPageIndex = async (data) => {
  const {getState} = store1;
  const {pageIndex, pageSize} = getState().EmployeesData ;
  
  const res = await newAPI.post("/employee", {pageIndex, pageSize});
  return res.data;
};

export const singleEmployeeOnlyBasicData = async (data) => {
  const res = await newAPI.get(`/employee/${data}`);
  return res.data[0];
};

export const addNewEmployeeData = async (employeeData) => {
  const result = await newAPI.post("employee/add", employeeData);
  return result;
};

export const filterEmployeeData = async (data) => {      // filter
  const {getState} = store1;
  const {pageIndex, pageSize} = getState().EmployeesData ;
  const itemobj = {
    pageIndex: pageIndex,
    pageSize: pageSize,
    name: data.name,
    email: data.email,
    status: data.status,
    designation: data.designation,
    employeeCode: data.employeeCode,
  };
  const result = await newAPI.post("/employee/filters", itemobj);
  return result.data;
};

export const getNextEmployeeCode = async () => {
  const result = await newAPI.get("employeeCodeFormat/next_employee_no");
  return result.data[0];
};

export const saveNextEmployeeCode = async () => {
  const result = await newAPI.get("employeeCodeFormat/save_next_number");
  return result;
};
////Delete
export const deleteEmployeeData = async (data) => {
  const result = await newAPI.delete(`employee/${data}`);
  return result;
};

export const singleEmployeeData = async (data) => {
  const employeeBasicAndPersonalData = await newAPI.get(`/employee/${data}`);
  const employeeAccount = await newAPI.get(`/employeeAccount/employee/${data}`);
  const employeeAddress = await newAPI.get(`/employeeAddress/employee/${data}`);
  const employeeDocument = await newAPI.get(
    `/employeeDocument/employee/${data}`
  );
  const employeeEducation = await newAPI.get(
    `/employeeEducation/employee/${data}`
  );
  const employeePayroll = await newAPI.get(`/employeePayroll/employee/${data}`);
 
  const employeeExperience = await newAPI.get(
    `/employeeExperience/employee/${data}`
  );
  //
  const employeeApprovedLeaves = await newAPI.get(`/leaves/employeeId/${data}`);
  //

  //

  return {
    employeeBasicAndPersonal: { ...employeeBasicAndPersonalData.data[0] },
    employeeAccount: employeeAccount.data,
    employeeAddress: { ...employeeAddress.data[0] },
    employeeDocument: employeeDocument.data,
    employeeEducationList: employeeEducation.data,
    employeeWorkExperienceList: employeeExperience.data,
    employeePayroll: employeePayroll.data,
    employeeLeaves: employeeApprovedLeaves.data,

    
  };
};

////////////////////////////////////////////////////////////////////////////////////////////////////////////////Address
///Get by Employee ID
export const addressByEmployeeId = async (data) => {
  const employeeAddress = await newAPI.get(`/employeeAddress/employee/${data}`);
 
  return employeeAddress.data[0];
};
///Address Add / Edit Post
export const addEditAddressEmployee = async (addressData) => {
  const result = await newAPI.post("employeeAddress/add", addressData);
  return result;
};
////////////////////////////////////////////////////////////////////////////////////////////////////////////////Account
///Get by Employee ID
export const accountByEmployeeId = async (data) => {
  const employeeAccount = await newAPI.get(`/employeeAccount/employee/${data}`);
  return employeeAccount.data;
};

///Account Add /Edit Post
export const addEditAccountEmployee = async (accData) => {
  const result = await newAPI.post("employeeAccount/add", accData);
  return result;
};

///////////////////////////////////////////////////////////////////////////////////////////////////////////////Educational
///Get by Employee ID
export const educationByEmployeeId = async (data) => {
  const employeeEducation = await newAPI.get(`/employeeEducation/employee/${data}`);
  return employeeEducation.data;
};
///Add
export const addEditEducationalEmployee = async (eduData) => {
  const result = await newAPI.post("employeeEducation/add", eduData);
  return result;
};
///Delete
export const deleteEducationalEmployeeData = async (data) => {
  const result = await newAPI.delete(`employeeEducation/${data}`);
  return result;
};
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////Experience
///Get by Employee ID
export const experienceByEmployeeId = async (data) => {
  const employeeExperience = await newAPI.get(`/employeeExperience/employee/${data}`);
  return employeeExperience.data;
};
//Add
export const addEditExperienceEmployee = async (expData) => {
  const result = await newAPI.post("employeeExperience/add", expData);
  return result;
};
///Delete
export const deleteExperienceEmployeeData = async (data) => {
  const result = await newAPI.delete(`employeeExperience/${data}`);
  return result;
};
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////Document
///Get by Employee ID
export const documentByEmployeeId = async (data) => {
  const employeeDocument = await newAPI.get(`/employeeDocument/employee/${data}`);
  return employeeDocument.data;
};

///Add
export const addEditDocumentEmployee = async (docData) => {
  const documentFile = docData.documentFile;
  const formData = new FormData();
  formData.append("documentFile", documentFile);
  formData.append("docData", JSON.stringify(docData));
  const result = await newAPI.post("employeeDocument/add", formData);
  return result;
};
//Delete Document
export const deleteDocumentData = async (data) => {
  const result = await newAPI.delete(`employeeDocument/${data}`);
  return result;
};
////////////////////////////////////////////////////////////////////////////////////////////////////////////Leaves
///Get by Employee ID
export const employeeLeavesdataOnly = async (id) => {
  const leaveListRes = await newAPI.get(`/leaves/employeeAllId/${id}`);
  return leaveListRes.data
};

//////////////////////////////////////////////////////////////////////////Delete Employee Payrolls By Employee Id
export const deleteEmployeePayrollsDataByEmployeeId = async (id) => {
  const result = await newAPI.delete(`employeePayrolls/employeeId/${id}`);
  return result;
};

export const addEditPayrollEmployee = async (data) => {
  const result = await newAPI.post("employeePayroll/add", data);
  return result;
};
//////Delete Payroll by Id
/////////////////////////////////////////////

///Archive The Employee Payroll / Delete the Payroll
export const deleteEmployeePayrollsData = async (id) => {
  const result = await newAPI.delete(`employeePayrolls/${id}`);
  return result;
};

///Add Employee Payroll
export const addEmployeePayroll = async (data) => {
  const result = await newAPI.post("employeePayrolls/add", data);
  return result;
};
