import { newAPI, basicAxios } from "../newAxios";
import axios from "axios";

//Refresh Auth Api
export const refreshAuthToken = async () => {
  const response = await basicAxios.get("auth/refresh");
  return response.data;
};
export const loginUserAuthRequest = async (data) => {
  try{
    const result = await basicAxios.post("auth", data);
    return result.data;
  } catch (error) {
    if (error.response && error.response.status === 400) {
      return error.response;
    } else if (error.response) {
      return error.response;
    } else {
      return { error: "Network error" };
    }
  }
};
export const logoutUserApi = async () => {
  const response = await basicAxios.get("auth/logout", {
    withCredentials: true,
  });
  return response.data;
};
export const addNewUserAuthRequest = async () => {
  const res = await newAPI.get(`user/${11}`);
  return res.data[0];
};
///Get Company details
export const getMyCompanyData = async () => {
  const respo = await axios.post(
    process.env.REACT_APP_GET_COMPANY_DATA_URL,
    {},
    {
      headers: {
        "X-Api-Key": process.env.REACT_APP_HEADER,
      },
    }
  );
 
  return JSON.parse(respo?.data?.body);
};
