import { takeLatest, all, call, put } from "redux-saga/effects";
import {
  startLookUpDetails,
  setLookUpDetailsData,
  setLookupQualityData,
  startAddEditNewLookupsDetails,


  setLookUpMasterData,
  startLookUpDetailsByLookupId,
  completedFetch,
  startLookUpMaster,
  startDeleteLookupDetails,
  startLookUpDetailsByLookupId1,
  startLookUpDetailsByLookupId2,
  startLookUpDetailsByLookupId3,
  setLookUpDetails1Data,
  setLookUpDetails2Data,
  setLookUpDetails3Data,
  startLookUpDetailsHistoryById,
} from "./lookupDetailsSlice.selector";

///API's
import {
  getLookUpDetailsData,
  GetLookUpDetailsDataByLookupIdAndPagination,
  addLookUpDetails,
  getLookupMasterData,
  GetLookUpDetailsDataByLookupId,
  deleteLookupDetailsData,
  GetLookUpHistoryDataByLookupId
} from "../../Api/LookUpAPI/lookUpAPI";
import { setNotificationAction } from "../NotificationStore/notification.slice.selector";
////////////////
////(1) Watcher for Full Details
function* startFullLookUp() {
  yield takeLatest(startLookUpDetails.type, setLookupDetails);
}
//(1) Next
function* setLookupDetails({ payload }) {
  try {
    let data;
    if (payload.id) {
      data = yield call(GetLookUpDetailsDataByLookupIdAndPagination, payload);
    } else {
      data = yield call(getLookUpDetailsData, payload);
    }

    yield put(setLookUpDetailsData(data));
    yield put(completedFetch());
  } catch (error) {
    yield put(
      setNotificationAction({
        message: error.message,
        variant: "error",
      })
    );
    yield put(completedFetch());
  }
}
//(2)Watcher
function* startAddDetails() {
  yield takeLatest(startAddEditNewLookupsDetails.type, setupAddDetails);
}
//(2)Next
function* setupAddDetails({ payload }) {
  try {
    yield call(addLookUpDetails, payload.data);
    yield put(startLookUpDetails(payload.tableData));
    yield put(
      setNotificationAction({
        message: "Successfully Updated",
        variant: "success",
      })
    );
    yield put(completedFetch());
  } catch (error) {
    yield put(
      setNotificationAction({
        message: error.message,
        variant: "error",
      })
    );
    yield put(completedFetch());
  }
}
//(3)
function* startFullLookUpMaster() {
  yield takeLatest(startLookUpMaster.type, setLookupMaster);
}
//(3) Next
function* setLookupMaster() {
  try {
    const data = yield call(getLookupMasterData);
    yield put(setLookUpMasterData(data));
    yield put(completedFetch());
  } catch (error) {
    yield put(
      setNotificationAction({
        message: error.message,
        variant: "error",
      })
    );
    yield put(completedFetch());
  }
}
//(4)Watcher
function* startLookUpDataByLookupId() {
  yield takeLatest(startLookUpDetailsByLookupId.type, setDataByLookupId);
}
//(4)Next
function* setDataByLookupId({ payload }) {
  try {
    const data = yield call(GetLookUpDetailsDataByLookupId, payload);
    yield put(setLookupQualityData(data));
    yield put(completedFetch());
  } catch (error) {
    yield put(
      setNotificationAction({
        message: error.message,
        variant: "error",
      })
    );
    yield put(completedFetch());
  }
}
//(4.1)Watcher
function* startLookUpDataByLookupId1() {
  yield takeLatest(startLookUpDetailsByLookupId1.type, setDataByLookupId1);
}
//(4.1)Next
function* setDataByLookupId1({ payload }) {
  try {
    const data1 = yield call(GetLookUpDetailsDataByLookupId, payload);
    yield put(setLookUpDetails1Data(data1));
    yield put(completedFetch());
  } catch (error) {
    yield put(
      setNotificationAction({
        message: error.message,
        variant: "error",
      })
    );
    yield put(completedFetch());
  }
}
//(4.2)Watcher
function* startLookUpDataByLookupId2() {
  yield takeLatest(startLookUpDetailsByLookupId2.type, setDataByLookupId2);
}
//(4.2)Next
function* setDataByLookupId2({ payload }) {
  try {
    const data = yield call(GetLookUpDetailsDataByLookupId, payload);
    yield put(setLookUpDetails2Data(data));
    yield put(completedFetch());
  } catch (error) {
    yield put(
      setNotificationAction({
        message: error.message,
        variant: "error",
      })
    );
    yield put(completedFetch());
  }
}
//(4.3)Watcher
function* startLookUpDataByLookupId3() {
  yield takeLatest(startLookUpDetailsByLookupId3.type, setDataByLookupId3);
}
//(4.3)Next
function* setDataByLookupId3({ payload }) {
  try {
    const data = yield call(GetLookUpDetailsDataByLookupId, payload);
    yield put(setLookUpDetails3Data(data));
    yield put(completedFetch());
  } catch (error) {
    yield put(
      setNotificationAction({
        message: error.message,
        variant: "error",
      })
    );
    yield put(completedFetch());
  }
}
//(5)Watcher
function* startDelete() {
  yield takeLatest(startDeleteLookupDetails.type, setUpForDelete);
}
//(5)Next
function* setUpForDelete({ payload }) {
  try {
    yield call(deleteLookupDetailsData, payload.data);
    yield put(startLookUpDetails(payload.tableData));
    yield put(
      setNotificationAction({
        message: "Successfully Deleted",
        variant: "error",
      })
    );
    yield put(completedFetch());
  } catch (error) {
    yield put(
      setNotificationAction({
        message: error.message,
        variant: "error",
      })
    );
    yield put(completedFetch());
  }
}

//(5.1)Watcher
function* startLookUpHistoryByLookupId1() {
  yield takeLatest(startLookUpDetailsHistoryById.type, setDataHistoryByLookupId1);
}
//(5.1)Next
function* setDataHistoryByLookupId1({ payload }) {
  try {
    const data1 = yield call(GetLookUpHistoryDataByLookupId, payload);
    yield put(setLookUpDetails1Data(data1));
    yield put(completedFetch());
  } catch (error) {
    yield put(
      setNotificationAction({
        message: error.message,
        variant: "error",
      })
    );
    yield put(completedFetch());
  }
}

///Accumulator
export default function* lookupDetailsDataSaga() {
  yield all([
    call(startFullLookUp),
    call(startAddDetails),
    call(startFullLookUpMaster),
    call(startLookUpDataByLookupId),
    call(startDelete),
    call(startLookUpDataByLookupId1),
    call(startLookUpDataByLookupId2),
    call(startLookUpDataByLookupId3),
    call(startLookUpHistoryByLookupId1)
  ]);
}
