import { takeLatest, all, call, put } from "redux-saga/effects";
import {
  startAllExpenses,
  startExpenseDataByPagination,
  startFilteredExpenseData,
  setAllExpensesData,
  startSingleExpense,
  setsingleExpense,
  completedFetch,
  startAddEditNewExpense,
  startDeleteExpense,
  startEmployeeExpense,
  setEmployeeExpense,
  startPieChartData,
  startAllPieChartData,
  setPieCharDataExpense,
  startPieChartDataByPaymentBy,
  startExpenseDetails,
  startExpenseRestore,
  setExpenseDetailsData,
} from "./expenseData.slice.selector";
import { setFormNavigate } from "../formnavigation/formnavigation";
import {
  getAllExpensesData,
  getSingleExpenseData,
  addExpenseMethod,
  deleteExpenseData,
  getEmployeeExpenseData,
  getPieChartData,
  getAllPieChartData,
  getPieChartDataGroupByPaymentBy,
  getExpenseDetailsDataByExpenseType,
  getExpensesDataByPagination,
  getFilteredExpensesData,
} from "../../Api/ExpensesAPI/expensesAPI";
import { changeRecordDataWithJSON } from "../../Api/api";
import { setNotificationAction } from "../NotificationStore/notification.slice.selector";

////////////////
////(1) Watcher for All Invoices
function* startAllExpensesWatcher() {
  yield takeLatest(startAllExpenses.type, setAllExpense);
}
////With Pagination

//(1) Next
function* setAllExpense({ payload }) {
  try {
    
    let expenseData;
      expenseData = yield call(getAllExpensesData);
      yield put(setAllExpensesData(expenseData));
    // }
    yield put(completedFetch());
  } catch (error) {
    yield put(
      setNotificationAction({
        message: error.message,
        variant: "error",
      })
    );
    yield put(completedFetch());
  }
}

///(1.1) with filter
function* startFilteredExpenseList() {
  yield takeLatest(startFilteredExpenseData.type, filterexpensesstore);
}

///(1.1)
function* filterexpensesstore({ payload }) {
  try {
    let allExpenseData
    allExpenseData = yield call(getFilteredExpensesData, payload);
    yield put(setAllExpensesData(allExpenseData));
    yield put(completedFetch());

  } catch (error) {
    yield put(
      setNotificationAction({
        message: error.message,
        variant: "error",
      })
    );
    yield put(completedFetch());
  }
}

////(1.2) With Pagination
function* startExpenseListDataByPagination() {
  yield takeLatest(startExpenseDataByPagination.type, filterpaginationstore);
}

///(1.2)
function* filterpaginationstore() {
  try {
    let allExpenseData
    allExpenseData = yield call(getExpensesDataByPagination);
    yield put(setAllExpensesData(allExpenseData));
    yield put(completedFetch());

  } catch (error) {
    yield put(
      setNotificationAction({
        message: error.message,
        variant: "error",
      })
    );
    yield put(completedFetch());
  }
}

///(2) Watcher for single Invoice
function* startsingleExpense() {
  yield takeLatest(startSingleExpense.type, setupSingleExpenseById);
}
///(2) Next
function* setupSingleExpenseById({ payload }) {
  try {
    const singleExpenseData = yield call(getSingleExpenseData, payload);
    yield put(setsingleExpense(singleExpenseData));
    yield put(completedFetch());
  } catch (error) {
    yield put(
      setNotificationAction({
        message: error.message,
        variant: "error",
      })
    );
    yield put(completedFetch());
  }
}
//(3) Watcher For Add Invoice
function* startAddExpense() {
  yield takeLatest(startAddEditNewExpense.type, setupAddNewExpense);
}
//(3)Next For Add Invoice
function* setupAddNewExpense({ payload }) {

  try {
    yield call(addExpenseMethod, payload);
    yield put(setFormNavigate(true));
    yield put(
      setNotificationAction({
        message: "Successfully Updated",
        variant: "success",
      })
    );
    yield put(completedFetch());
  } catch (error) {
    yield put(
      setNotificationAction({
        message: error.message,
        variant: "error",
      })
    );
    yield put(completedFetch());
  }
}
//(4)Watcher Delete
function* startExpenseDelete() {
  yield takeLatest(startDeleteExpense.type, setUpForDelete);
}
//(4) Delete
function* setUpForDelete({ payload }) {
  try {
    yield call(deleteExpenseData, payload.data);
    yield put(startExpenseDataByPagination(payload.tableData));
    yield put(
      setNotificationAction({
        message: "Successfully Deleted",
        variant: "error",
      })
    );
    yield put(completedFetch());
  } catch (error) {
    yield put(
      setNotificationAction({
        message: error.message,
        variant: "error",
      })
    );
    yield put(completedFetch());
  }
}
//(5) Watcher For Add  Expense Restore
function* start_startAddRestoreExpense() {
  yield takeLatest(startExpenseRestore.type, set_startAddRestoreExpense);
}
//(5)Next
function* set_startAddRestoreExpense({ payload }) {
  try {
    yield call(changeRecordDataWithJSON, payload);
    yield put(startExpenseDataByPagination(payload.tableData));
    yield put(
      setNotificationAction({
        message: "Successfully Updated",
        variant: "success",
      })
    );
  } catch (error) {
    yield put(
      setNotificationAction({
        message: error.message,
        variant: "error",
      })
    );
    yield put(completedFetch());
  }
}

///////////////////////////////////////////////////////////////////////////////////////////////////////REPORT
///(6) Watcher for single Employee
function* startsingleEmployeeExpense() {
  yield takeLatest(startEmployeeExpense.type, setupSingleEmployeeExpenseById);
}
///(6) Next
function* setupSingleEmployeeExpenseById({ payload }) {
  try {
    const singleExpenseData = yield call(getEmployeeExpenseData, payload);
    yield put(setEmployeeExpense(singleExpenseData));
    yield put(completedFetch());
  } catch (error) {
    yield put(
      setNotificationAction({
        message: error.message,
        variant: "error",
      })
    );
    yield put(completedFetch());
  }
}

///(7) Watcher
function* startPieDataExpense() {
  yield takeLatest(startPieChartData.type, setup_PieChartData);
}
///(7) Next
function* setup_PieChartData({ payload }) {
  try {
    const data = yield call(getPieChartData, payload);
    yield put(setPieCharDataExpense(data));
    yield put(completedFetch());
  } catch (error) {
    yield put(
      setNotificationAction({
        message: error.message,
        variant: "error",
      })
    );
    yield put(completedFetch());
  }
}

///(8) Watcher
function* startAllPieDataExpense() {
  yield takeLatest(startAllPieChartData.type, setup_AllPieChartData);
}

///(8) Next
function* setup_AllPieChartData({ payload }) {
  try {
    const data = yield call(getAllPieChartData, payload);
    yield put(setPieCharDataExpense(data));
    yield put(completedFetch());
  } catch (error) {
    yield put(
      setNotificationAction({
        message: error.message,
        variant: "error",
      })
    );
    yield put(completedFetch());
  }
}

///(9) Watcher
function* startPieDataExpenseGroupBypaymentBy() {
  yield takeLatest(
    startPieChartDataByPaymentBy.type,
    setup_PieChartDataByPaymentBy
  );
}
///(9) Next
function* setup_PieChartDataByPaymentBy({ payload }) {
  try {
    const data = yield call(getPieChartDataGroupByPaymentBy, payload);
    yield put(setPieCharDataExpense(data));
    yield put(completedFetch());
  } catch (error) {
    yield put(
      setNotificationAction({
        message: error.message,
        variant: "error",
      })
    );
    yield put(completedFetch());
  }
}

///(10) Watcher
function* start_ExpenseDetails() {
  yield takeLatest(startExpenseDetails.type, setup_ExpenseDetailsData);
}
///(10) Next
function* setup_ExpenseDetailsData({ payload }) {
  try {
    const data = yield call(getExpenseDetailsDataByExpenseType, payload);
    yield put(setExpenseDetailsData(data));
    yield put(completedFetch());
  } catch (error) {
    yield put(
      setNotificationAction({
        message: error.message,
        variant: "error",
      })
    );
    yield put(completedFetch());
  }
}
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////EXPENSE REPORTs

///Accumulator
export default function* expensesDataSaga() {
  yield all([
    call(startAllExpensesWatcher),
    call(startExpenseListDataByPagination),
    call(startFilteredExpenseList),
    call(startsingleExpense),
    call(startAddExpense),
    call(startExpenseDelete),
    call(startsingleEmployeeExpense),
    call(startPieDataExpense),
    call(startAllPieDataExpense),
    call(startPieDataExpenseGroupBypaymentBy),
    call(start_ExpenseDetails),
    call(start_startAddRestoreExpense),
  ]);
}
