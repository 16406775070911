import { newAPI } from "../newAxios";

export const getCountryMasterData = async () => {
  const res = await newAPI.get("/country/country_list/1");
  return res.data;
};
export const getCountryMasterData1 = async () => {
  const res = await newAPI.get("/country");
  return res.data;
};

export const deleteCountryMasterData = async (id) => {
  const result = await newAPI.delete(`country/${id}`);
  return result;
};

export const addCountryMasterData = async (data) => {
  const result = await newAPI.post("country/add", data);
  return result;
};
export const getCountryMasterDataById = async (id) => {
  const res = await newAPI.get(`country/${id}`);
  return res.data[0];
};
