import { newAPI } from "../newAxios";

export const leaveData = async () => {
  const res = await newAPI.get("/leaves");
  return res.data;
};

export const singleLeaveData = async (id) => {
  const res = await newAPI.get(`/leaves/${id}`);
  return res.data[0];
};

export const addOrEditorApproveLeave = async (data) => {
  const result = await newAPI.post("leaves/add", data);
  return result;
};

export const deleteLeaveData = async (id) => {
  const result = await newAPI.delete(`leaves/${id}`);
  return result;
};

export const employeeLeavesdata = async (id) => {
  const summaryRes = await newAPI.get(`/leaves/employeeId/${id}`);
  const leaveListRes = await newAPI.get(`/leaves/employeeAllId/${id}`);
  return {
    employeeLeaveSummary: summaryRes.data,
    employeeLeavesLsit: leaveListRes.data,
  };
};

export const findTheOverLappingDates = async (data) => {
  const result = await newAPI.post("leaves/overLappingDates", data);
  return result.data;
};


export const findTheEmployeesleaveGroupBy = async (data) => {
  const result = await newAPI.post("leaves/employeesleaveGroupBy", data);
  return result.data;
};