import { newAPI } from "../newAxios";

export const companyMaster = async () => {
  const result = await newAPI.get("company");
  return result.data[0];
};

export const companyMasterPost = async (companyData) => {
  const result = await newAPI.post("company/add", companyData);
  return result;
};
