import { lazy, useEffect } from 'react';

import { LocalizationProvider } from '@mui/x-date-pickers';
import '@mui/lab';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { SnackbarProvider, useSnackbar } from 'notistack';
import NavigationScroll from 'layout/NavigationScroll';

import ThemeCustomization from 'themes';

import { JWTProvider as AuthProvider } from 'contexts/JWTContext';

// ================================================================================|| APP ||========================================================================== //
import MinimalLayout from 'layout/MinimalLayout';
import NavMotion from 'layout/NavMotion';
import Loadable from 'ui-component/Loadable';
import { Routes, Route, Navigate } from 'react-router-dom';
import MapRoutes from 'routes/MapRoutes';
import { useDispatch, useSelector } from 'react-redux';

import { usersDataSelector } from 'store1/user login data/user.login.data.slice.slector';
import { notificationDetailsSelector } from 'store1/NotificationStore/notification.slice.selector';
const AuthPage = Loadable(lazy(() => import('./Routes1/AuthPageRoute/auth.page')));
const ProfilePage = Loadable(lazy(() => import('./component/HeaderNavigation/Profile/profilePage')));
const MainLayout = Loadable(lazy(() => import('layout/MainLayout')));
const AuthGuard = Loadable(lazy(() => import('utils/route-guard/AuthGuard')));
const CompanyCode = Loadable(lazy(() => import('Routes1/CompanyCodeRoute/companyCode')));
const Logout = Loadable(lazy(() => import('Modules/Logout')));
const MainContent = () => {
    const dispatch = useDispatch();
    const loggedInUserData = useSelector(usersDataSelector);
    const notificationDetails = useSelector(notificationDetailsSelector);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const notificationHandlerVariant = (textMessage = 'This is Temprory Message', variant = 'info') => {
        enqueueSnackbar(textMessage, { variant, autoHideDuration: 2000 });
    };

    useEffect(() => {
        if (notificationDetails.message.length > 5) {
            const { message, variant } = notificationDetails;
            notificationHandlerVariant(message, variant);
        }
    }, [notificationDetails]);
    
    return (
        <ThemeCustomization>
            <NavigationScroll>
                <AuthProvider>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Routes>
                            <Route
                                element={
                                    <NavMotion>
                                        <MinimalLayout />
                                    </NavMotion>
                                }
                            >
                                <Route path="/auth" element={<AuthPage />} />
                                <Route path="/logout" element={<Logout />} />
                                <Route path="/company-code" element={<CompanyCode />} />
                            </Route>
                            <Route
                                element={
                                    <AuthGuard>
                                        {loggedInUserData?.userName ? <MainLayout /> : <Navigate to="/auth" replace={true} />}
                                    </AuthGuard>
                                }
                            >
                                <Route path="/userprofile" element={<ProfilePage />} />
                                {MapRoutes.map((data) => (
                                    <Route exact path={data.path} element={<data.element />} />
                                ))}
                            </Route>
                        </Routes>
                        {/* <Snackbar /> */}
                    </LocalizationProvider>
                </AuthProvider>
            </NavigationScroll>
        </ThemeCustomization>
    );
};

function App() {
    return (
        <SnackbarProvider maxSnack={1} autoHideDuration={1000}>
            <MainContent />
        </SnackbarProvider>
    );
}
export default App;
