import { takeLatest, all, call, put } from "redux-saga/effects";
import {
  startAllInvoices,
  startInvoicesListDataByPageIndex,
  startFilterInvoicesListData,
  setAllInvoicesData,
  startSingleInvoice,
  setsingleInvoice,
  completedFetch,
  startSingleInvoiceDetailsByInvoiceNumber,
  startAddNewInvoice,
  setSingleInvoiceDetailsByInvoiceNumber,
  startAddingInvoiceDetails,
  startDeleteInvoice,
  startDeleteInvoiceDetails,
  setCreatePrams,
  startAddRestoreInvoice,
} from "./invoiceData.slice.selector";
///API's
import { changeRecordData } from "../../Api/api";
import {
  allInvoiceData,
  invoiceListDataByPageIndex,
  singleInvoiceDataByID,
  addInvoiceDetailsData,
  singleInvoiceDescriptionDataByInvoiceId,
  addNewInvoiceData,
  deleteInvoiceData,
  // saveNextInvoiceNumber,
  deleteInvoiceDetails,
  invoiceFilterListData,
} from "../../Api/InvoicesAPI/invoicesAPI";
import { setSingleClientData } from "store1/client data/client.data.slice.selector";
import { setNotificationAction } from "../NotificationStore/notification.slice.selector";
////////////////
////(1) Watcher for All Invoices
function* startAllInvoice() {
  yield takeLatest(startAllInvoices.type, setAllInvoice);
}
////With Pagination
// function* startInvoiceListDataByPageIndex() {
//   yield takeLatest(startInvoicesListDataByPageIndex.type, setAllInvoice);
// }
/// with filter
// function* startFilterInvoiceListData() {
//   yield takeLatest(startFilterInvoicesListData.type, setAllInvoice);
// }
//(1) Next
function* setAllInvoice({ payload }) {
  try {
    let invoicesData;
    // if (payload?.pageSize) {
    //   invoicesData = yield call(invoiceListDataByPageIndex, payload);
    //   yield put(setAllInvoicesData(invoicesData));
    // } else {
    invoicesData = yield call(allInvoiceData);
    yield put(setAllInvoicesData(invoicesData));
    // }
    yield put(completedFetch());
  } catch (error) {
    yield put(
      setNotificationAction({
        message: error.message,
        variant: "error",
      })
    );
    yield put(completedFetch());
  }
}

///(1.1) With Pagination
function* startInvoiceListDataByPageIndex() {
  yield takeLatest(startInvoicesListDataByPageIndex.type, setPaginationAllAllInvoice);
}

//(1.1) Next
function* setPaginationAllAllInvoice() {
  try {
    let invoicesData;
    invoicesData = yield call(invoiceListDataByPageIndex);
    yield put(setAllInvoicesData(invoicesData));
    yield put(completedFetch());

  } catch (error) {
    yield put(
      setNotificationAction({
        message: error.message,
        variant: "error",
      })
    );
    yield put(completedFetch());
  }
}

///(1.2) with filter
function* startFilterInvoiceListData() {
  yield takeLatest(startFilterInvoicesListData.type, setAllFilterData);
}
//(1.2) Next
function* setAllFilterData({ payload }) {
  try {
    let invoicesData;
      invoicesData = yield call(invoiceFilterListData, payload);
      yield put(setAllInvoicesData(invoicesData));
    // }
    yield put(completedFetch());

  } catch (error) {
    yield put(
      setNotificationAction({
        message: error.message,
        variant: "error",
      })
    );
    yield put(completedFetch());
  }
}

///(2) Watcher for single Invoice
function* startsingleInvoice() {
  yield takeLatest(startSingleInvoice.type, setInvoiceData);
}
///(2) Next
function* setInvoiceData({ payload }) {
  try {
    const singleInvoiceData = yield call(singleInvoiceDataByID, payload);
    yield put(setsingleInvoice(singleInvoiceData));
    yield put(completedFetch());
  } catch (error) {
    yield put(
      setNotificationAction({
        message: error.message,
        variant: "error",
      })
    );
    yield put(completedFetch());
  }
}
//(4) Watcher For Add Invoice
function* startAddInvoices() {
  yield takeLatest(startAddNewInvoice.type, setupAddNewInvoice);
}
//(4)Next
function* setupAddNewInvoice({ payload }) {
  try {
    if (payload.id) {
      yield call(addNewInvoiceData, payload);
      yield put(startSingleInvoiceDetailsByInvoiceNumber(payload.id));
    } else {
      const result = yield call(addNewInvoiceData, payload);
      yield put(setSingleClientData({}));
      yield put(setCreatePrams(result.data.insertId));
    }
    yield put(
      setNotificationAction({
        message: "Added Successfully",
        variant: "success",
      })
    );
    yield put(completedFetch());
  } catch (error) {
    yield put(
      setNotificationAction({
        message: error.message,
        variant: "error",
      })
    );
    yield put(completedFetch());
  }
}
//(5)watcher
function* startSingleInvoiceDetail() {
  yield takeLatest(
    startSingleInvoiceDetailsByInvoiceNumber.type,
    setupForInvoiceDetails
  );
}
//(5) Next
function* setupForInvoiceDetails({ payload }) {
  try {
    const data = yield call(singleInvoiceDescriptionDataByInvoiceId, payload);
    yield put(setSingleInvoiceDetailsByInvoiceNumber(data));
    yield put(completedFetch());
  } catch (error) {
    yield put(
      setNotificationAction({
        message: error.message,
        variant: "error",
      })
    );
    yield put(completedFetch());
  }
}
//(6)Watcher
function* startAddDetailsOfInvoice() {
  yield takeLatest(startAddingInvoiceDetails.type, setupAddDetailsOfInvoice);
}
//(6)Next
function* setupAddDetailsOfInvoice({ payload }) {
  try {
    yield call(addInvoiceDetailsData, payload);
    yield put(startSingleInvoiceDetailsByInvoiceNumber(payload.invoice_id));
    yield put(
      setNotificationAction({
        message: "Successfully Added",
        variant: "success",
      })
    );
    yield put(completedFetch());
  } catch (error) {
    yield put(
      setNotificationAction({
        message: error.message,
        variant: "error",
      })
    );
    yield put(completedFetch());
  }
}
//(5)Watcher
function* startinvoiceDelete() {
  yield takeLatest(startDeleteInvoice.type, setUpForDelete);
}
//(5)
function* setUpForDelete({ payload }) {
  try {
    yield call(deleteInvoiceData, payload.data);
    yield put(startInvoicesListDataByPageIndex(payload.tableData));
    yield put(
      setNotificationAction({
        message: "Successfully Deleted",
        variant: "error",
      })
    );
    yield put(completedFetch());
  } catch (error) {
    yield put(
      setNotificationAction({
        message: error.message,
        variant: "error",
      })
    );
    yield put(completedFetch());
  }
}
//(6)Watcher
function* startinvoiceDetailsDelete() {
  yield takeLatest(startDeleteInvoiceDetails.type, setUpForInvoiceDetailDelete);
}
//(6)
function* setUpForInvoiceDetailDelete({ payload }) {
  const { id, invoice_id } = payload
  try {
    yield call(deleteInvoiceDetails, id);
    yield put(startSingleInvoiceDetailsByInvoiceNumber(invoice_id));
    yield put(
      setNotificationAction({
        message: "Successfully Deleted",
        variant: "error",
      })
    );
    yield put(completedFetch());
  } catch (error) {
    yield put(
      setNotificationAction({
        message: error.message,
        variant: "error",
      })
    );
    yield put(completedFetch());
  }
}
//(7) Watcher For Add Invoice Restore
function* start_startAddRestoreInvoice() {
  yield takeLatest(startAddRestoreInvoice.type, set_startAddRestoreInvoice);
}
//(7)Next
function* set_startAddRestoreInvoice({ payload }) {
  try {
    yield call(changeRecordData, payload);
    yield put(startInvoicesListDataByPageIndex(payload.tableData));
    yield put(
      setNotificationAction({
        message: "Successfully Restored",
        variant: "success",
      })
    );
    yield put(completedFetch());
  } catch (error) {
    yield put(
      setNotificationAction({
        message: error.message,
        variant: "error",
      })
    );
    yield put(completedFetch());
  }
}
///Accumulator
export default function* invoiceDataSaga() {
  yield all([
    call(startAllInvoice),
    call(startInvoiceListDataByPageIndex),
    call(startFilterInvoiceListData),
    call(startsingleInvoice),
    call(startAddInvoices),
    call(startSingleInvoiceDetail),
    call(startAddDetailsOfInvoice),
    call(startinvoiceDelete),
    call(startinvoiceDetailsDelete),
    call(start_startAddRestoreInvoice),
  ]);
}
