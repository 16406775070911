import { newAPI } from "../newAxios";
import { store1 } from "../../store1/store1";

export const paymentsData = async () => {
  const res = await newAPI.get("/payment");
  return res.data;
};

export const paymentsDataByPageIndex = async () => {
  const {getState} = store1;
  const {pageIndex, pageSize} = getState().PaymentsData;
  const res = await newAPI.post("/payment", {pageIndex, pageSize});
  return res.data;
};

export const paymentsDataFilter = async (data) => {
  const {getState} = store1;
  const {pageIndex, pageSize} = getState().PaymentsData;
  const payobj = {
    pageIndex: pageIndex,
    pageSize: pageSize,
    clientName: data.clientName,
    payment_mode: data.payment_mode,
    reference: data.reference,
    startDateValue: data.startDateValue,
    endDateValue: data.endDateValue
  };
  const res = await newAPI.post("/payment/dynamic-filter", payobj);
  return res.data;
};

export const singleClientPaymentsData = async (id) => {
  const res = await newAPI.get(`/payment/${id}`);
  return res.data;
};

export const addInvoicePaymentsDetails = async (paymentData) => {
  
  const res = await newAPI.post(`/payment/add_inovice`, paymentData);
  return res.data;
};

export const filterInvoicePaymentsDetails = async (paymentData) => {
  const res = await newAPI.post(`/invoice/get-client-invoices/`,paymentData);
  
  return res.data;
  
};
 
export const singleClientPaymentsDetails = async (id) => {
  const res = await newAPI.get(`/payment/get_invoices/${id}`);
  return res.data;
};

export const deletePaymentInvoiceData = async (data) => {
  const res = await newAPI.post("/payment/delete_payment_invoice",data);
  return res.data;
};

export const addNewPaymentData = async (paymentData) => {
  const result = await newAPI.post("payment/add", paymentData);
 return result;
};
export const deletePaymentData = async (id) => {
  
  const result = await newAPI.delete(`payment/${id}`);
  return result;
};