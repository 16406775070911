import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";

const clientsDataSlice = createSlice({
  name: "clientsDataSliceName",
  initialState: {
    isLoading: false,
    allClientsData: [],
    clientListWithPage: [],
    singleClientData: {},
    loaderMessage: "",
    backDrop: false,
    filterclientda: {},
    pageIndex: 1,
    pageSize: 10,
  },
  reducers: {
    setPage(state, action) {
      state.pageIndex = action.payload;
    },
    setRowsPerPage(state, action) {
      state.pageSize = action.payload;
    },
    startAllClientsData(state, action) {
      state.isLoading = true;
      state.loaderMessage = "Loading please wait...";
    },
    startClientsDataByPageIndex(state, action) {
      state.isLoading = true;
      state.loaderMessage = "Loading please wait...";
    },
    startFilterClientsData(state, action) {
      state.isLoading = true;
      state.loaderMessage = "Loading please wait...";
    },
    setAllClientsData(state, action) {
      state.allClientsData = action.payload;
    },
    startFilterData(state, action) {
      state.isLoading = true;
      state.loaderMessage = "Loading please wait...";
    },
    setFilterData(state, action) {
      state.filterclientda = action.payload;
    },
    setClientListWithPage(state, action) {
      state.clientListWithPage = action.payload;
    },
    startSingleClientData(state, action) {
      state.isLoading = true;
      state.loaderMessage = "Loading please wait...";
    },
    setSingleClientData(state, action) {
      state.singleClientData = action.payload;
    },
    startAddOrEditClient(state, action) {
      state.isLoading = true;
      state.loaderMessage = "Loading please wait...";
    },

    startDeleteClient(state, action) {
      state.isLoading = true;
    },
    completedFetch(state, action) {
      state.isLoading = false;
    },
    startAddRestoreClient(state, action) {
      state.isLoading = true;
    },
  },
});

export const {
  setPage,
  setRowsPerPage,
  startAllClientsData,
  startClientsDataByPageIndex,
  startFilterClientsData,
  setAllClientsData,
  startFilterData,
  setFilterData,
  setClientListWithPage,
  completedFetch,
  startSingleClientData,
  setSingleClientData,
  startAddOrEditClient,
  startDeleteClient,
  startAddRestoreClient
} = clientsDataSlice.actions;
export default clientsDataSlice.reducer;

///////////////////////////////////////////////////////////////
///selector
const mainClientState = (state) => state.ClientsData;
//
export const isLoadingSelectorClient = createSelector(
  [mainClientState],
  (clientDataState) => clientDataState.isLoading
);
//
export const allClientsDataSelector = createSelector(
  [mainClientState],
  (clientDataState) => clientDataState.allClientsData
);
//
export const FilterDataSelector = createSelector(
  [mainClientState],
  (clientDataState) => clientDataState.filterclientda
);
//
export const clientListDataWithPageSelector = createSelector(
  [mainClientState],
  (clientDataState) => clientDataState.clientListWithPage
);
//
export const clientDataSelector = createSelector(
  [mainClientState],
  (clientDataState) => clientDataState.singleClientData
);
//

export const loaderMessageSelector = createSelector(
  [mainClientState],
  (clientDataState) => clientDataState.loaderMessage
);
//

export const pageIndexSelector = createSelector(
  [mainClientState],
  (clientDataState) => clientDataState.pageIndex
);
//

export const pageSizeSelector = createSelector(
  [mainClientState],
  (clientDataState) => clientDataState.pageSize
);
