import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";

const lookUpDetailsDataSlice = createSlice({
  name: "lookUpDetailsDataSlice",
  initialState: {
    isLoading: false,
    lookupDetailsData: [],
    lookupQualityData: [],
    lookupMasterData: [],
    lookupDetailForId1: [],
    lookupDetailForId2: [],
    lookupDetailForId3: [],
  },
  reducers: {
    startLookUpDetails(state, action) {
      state.isLoading = true;
    },
    startLookUpMaster(state, action) {
      state.isLoading = true;
    },
    startLookUpDetailsByLookupId(state, action) {
      state.isLoading = true;
    },
    startLookUpDetailsByLookupId1(state, action) {
      state.isLoading = true;
    },
    startLookUpDetailsHistoryById(state, action) {
      state.isLoading = true;
    },
    setLookUpDetails1Data(state, action) {
      state.lookupDetailForId1 = action.payload;
    },
    startLookUpDetailsByLookupId2(state, action) {
      state.isLoading = true;
    },
    setLookUpDetails2Data(state, action) {
      state.lookupDetailForId2 = action.payload;
    },
    startLookUpDetailsByLookupId3(state, action) {
      state.isLoading = true;
    },
    setLookUpDetails3Data(state, action) {
      state.lookupDetailForId3 = action.payload;
    },
    setLookUpDetailsData(state, action) {
      state.lookupDetailsData = action.payload;
    },
    setLookupQualityData(state, action) {
      state.lookupQualityData = action.payload;
    },
    setLookUpMasterData(state, action) {
      state.lookupMasterData = action.payload;
    },
    startAddEditNewLookupsDetails(state, action) {
      state.isLoading = false;
    },

    startDelete(state, action) {
      state.isLoading = true;
    },
    startDeleteLookupDetails(state, action) {
      state.isLoading = true;
    },
    completedFetch(state, action) {
      state.isLoading = false;
    },
  },
});
///
export const {
  startLookUpDetails,
  setLookUpDetailsData,
  setLookupQualityData,
  startAddEditNewLookupsDetails,
  startDelete,
  startLookUpMaster,
  setLookUpMasterData,
  startLookUpDetailsByLookupId,
  startDeleteLookupDetails,
  startLookUpDetailsByLookupId1,
  startLookUpDetailsByLookupId2,
  startLookUpDetailsByLookupId3,
  startLookUpDetailsHistoryById,
  setLookUpDetails1Data,
  setLookUpDetails2Data,
  setLookUpDetails3Data,
  completedFetch,
} = lookUpDetailsDataSlice.actions;

export default lookUpDetailsDataSlice.reducer;

/////Selectors
const mainLookupDetailsState = (state) => state.LookupDetails;
//
export const isLoadingSelector = createSelector(
  [mainLookupDetailsState],
  (lookUpState) => lookUpState.isLoading
);
//
export const lookUpDetailsSelector = createSelector(
  [mainLookupDetailsState],
  (lookUpState) => lookUpState.lookupDetailsData
);
//
export const lookUpDetailByIdSelector = createSelector(
  [mainLookupDetailsState],
  (lookUpState) => {
    return {
      lookupDetailForId1: lookUpState.lookupDetailForId1,
      lookupDetailForId2: lookUpState.lookupDetailForId2,
      lookupDetailForId3: lookUpState.lookupDetailForId3,
    };
  }
);
//
export const lookUpMasterSelector = createSelector(
  [mainLookupDetailsState],
  (lookUpState) => lookUpState.lookupMasterData
);
//
export const lookUpQualitySelector = createSelector(
  [mainLookupDetailsState],
  (lookUpState) => lookUpState.lookupQualityData
);
